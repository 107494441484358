import React from "react";

export default function InquiriesSentChatScreen() {
  return (
    <div className="Inquiries-Sent-Chat bg-layout drop-shadow-md lg:w-[152vh] lg:h-[70vh] lg:ml-[17rem] relative top-[1rem] fade-in-left">
      <div className="flex flex-row mt-3 xs:hidden">
          <nav aria-label="breadcrumb" class="w-max">
            <ol class="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li class="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <a href="#" class="opacity-60">
                  Home
                </a>
                <span class="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li class="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <a href="#" class="opacity-60">
                  Profile
                </a>
                <span class="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li class="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <a href="#" class="opacity-60">
                  Send Inquiries
                </a>
                <span class="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li class="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <a href="#">Inquiries</a>
              </li>
            </ol>
          </nav>
          </div>
      <div className="flex-1 sm:p-4 justify-between flex flex-row xs:flex-col lg:h-[70vh] ">
        <div className=" flex flex-col gap-3 xs:mx-3 ">
          <div className=" bg-gray-200 lg:w-[541px] ml-[2.1rem] p-1 mt-5 rounded-md">
            <h1>Sent to : Supplier name</h1>
          </div>
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
            <label
              htmlFor="input"
              className="text text-sm text-black w-[7rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Your Message
            </label>
            <textarea
              placeholder="Enter your Message or inquiry here..."
              name="ProductDetail"
              rows="7"
              className=" px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white    p-[10px]"
              required
              autoComplete="off"
            ></textarea>
          </div>
        </div>
        <div className=" my-auto xs:mx-auto">
          <div className=" flex items-center my-2">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
              required
              autoComplete="off"
            />
            <label
              for="link-checkbox"
              className="xs:text-[12px] ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
            >
              Share Bussiness Card with Supplier
            </label>
          </div>
          <div className=" flex items-center my-2 ">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
              required
              autoComplete="off"
            />
            <label
              for="link-checkbox"
              className="xs:text-[12px] ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
            >
              Request Bussiness Card with Supplier
            </label>
          </div>

          <div className="flex flex-col xs:gap-3 justify-end">
            <button className=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center">
              Send Message
            </button>

            <button className=" border border-red-500 rounded-full xs:mb-3 font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
