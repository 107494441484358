import React, { useEffect, useState } from "react";
import { useWallet } from "../../Wallet/Context/WalletContext";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineSend, AiOutlineUserAdd } from "react-icons/ai";
import img from "../staticimages/coin.png";
import { MdCallReceived } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import EditProfile from "./ProfileStock/EditProfile";
import ProfilePostHistory from "./ProfileStock/ProfilePostHistory";

export default function Userprofile() {
  const { profiledata } = useWallet();

  const [mountComponent, setmountComponent] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const routeBussinessCard = (businessCardId) => {
    navigate(`/findproduct/businessviewCard/${businessCardId}`);
  };

  //

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };
  const handleButtonClick = () => {
    setmountComponent(!mountComponent);
  };
  return (
    <>
      <div>
        {mountComponent ? (
          <>
            {profiledata && (
              <>
                <div className="bg-layout  shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left ">
                  <div>
                    <div
                      data-dial-init=""
                      className={`fixed top-6 right-12 group flex flex-col-reverse gap-1 fade-in-left ${
                        isMenuOpen ? "open" : ""
                      }`}
                    >
                      <div
                        id="speed-dial-menu-dropdown "
                        className={`flex flex-col justify-end  ${
                          isMenuOpen ? "" : "hidden"
                        } py-1 mb-4 space-y-2 bg-white border border-gray-100 rounded-lg shadow-sm shark:border-gray-600 shark:bg-gray-700  fade-in-right`}
                      >
                        <ul className="text-sm text-gray-700 p-2 ">
                          <li onClick={handleButtonClick}>
                            <span className="flex  cursor-pointer items-center px-5 py-2 hover:bg-gray-300 hover:text-black border rounded-md ">
                              <FaEdit className="w-3.5 h-3.5 me-2 cursor-pointer" />
                              <span className="text-sm font-medium cursor-pointer">
                                edit profile
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <button
                        type="button"
                        onClick={handleToggleMenu}
                        aria-controls="speed-dial-menu-dropdown"
                        aria-expanded={isMenuOpen}
                        className="flex items-center border border-gray-300 justify-center  text-gray-700 rounded-full w-14 h-14 shark:hover:bg-blue-700 focus:ring-4 ring-blue-300 "
                      >
                        <HiDotsVertical className="text-[30px]" />
                        <span className="sr-only">Open actions menu</span>
                      </button>
                    </div>
                  </div>

                  <div>
                    <div className="--edit - flex flex-col justify-center xs:mx-auto  items-center">
                      <div className="--items ">
                        {/* a */}
                        <div className="--banner-bg mt-8">
                          <img
                            className="rounded-md h-[10.1rem] xs:m-auto xs:w-[80%] w-[100%]"
                            src={profiledata.coverPic}
                            alt=""
                          />
                        </div>
                        <div className="flex flex-row">
                          <div className="--user-img">
                            {/* <img
                              className=" w-auto h-[112px] 

relative top-[-48px] ml-[22px] rounded-[42%] border-[2px] border-yellow-300"
                              src={profiledata.profilePic}
                              alt=""
                            /> */}

                            <div className=" relative bottom-7 left-2">
                              <img
                                className=" h-[6rem] w-[6rem] xs:ml-10 xs:mr-5 rounded-full object-cover"
                                src={profiledata.profilePic}
                                alt="Avatar"
                              />
                              <div className=" absolute inset-0 rounded-full shadow-inner"></div>
                            </div>

                            {/* <img
  alt="..."
  src={profiledata.profilePic}

  className="shadow-lg rounded-full mx-auto max-w-120-px  max--120-px"
/> */}
                          </div>
                          <div className="flex flex-row items-center lg:gap-[28rem]">
                            <div className="--user-name flex w-auto lg:ml-4 flex-col lg:mt-[6px]">
                              <span className="font-all overflow-text text-[#060606] font-extrabold lg:text-[21px]">
                                {profiledata.name}
                              </span>
                              <span className="font-all overflow-text italic ">
                                {profiledata.bio}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* b */}
                        <div className="lg:border-[1px] border-[#D2D2D2] rounded-md xs:border-y xs:rounded-none ">
                          <h1 className="intrest font-all txt-[#263238] xs:ml-2 overflow-text font-semibold text-[17px] p-[10px] ">
                            Interest :
                          </h1>
                          <div className=" coolinput flex gap-1 w-fit-content static lg:max-w-240 xs:w-[80%]  ml-[28px] mr-[28px] mb-4 ">
                            {profiledata.interest.map((interest, index) => (
                              <span key={index} className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-all font-semibold bg-[#fac8ca] text-black">
                                {interest.trim()}
                              </span>
                            ))}
                          </div>
                        </div>
                        {/* c  */}
                        {/* fr a  */}
                        <div class="grid lg:grid-cols-2 sm:grid-cols-1 xs:px-3  my-3 xs:my-0 lg:gap-[8px]">
                          <div className="aa">
                            <div className="lg:border-[1px] xs:p-[7px] lg:w-[25rem] border-[#D2D2D2] lg:rounded-md xs:border-y  lg:p-[8px] ">
                              <h1 className="intrest font-all  txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[15rem]">
                                Location : <span className="text-[16px]"></span>
                              </h1>

                              <p className="font-all text-[#000000] text-center lg:mt-[13px] overflow-text">
                                {profiledata.location}
                              </p>
                            </div>
                            <div className="lg:border-[1px] xs:p-[7px] lg:w-[25rem] border-[#D2D2D2] lg:rounded-md xs:border-y  lg:p-[8px] ">
                              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[15rem] ">
                                Language :{" "}
                                <span className="text-[16px]"> </span>
                              </h1>
                              <p className="font-all text-[#000000] text-center lg:mt-[13px]">
                                English--(British America)
                              </p>
                            </div>
                          </div>

                          <div className="bb">
                            <div className="lg:border-[1px] xs:p-[7px] lg:w-[25rem] border-[#D2D2D2] lg:rounded-md xs:border-y  lg:p-[8px] ">
                              <h1 className="intrest font-all overflow-text txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[11rem] ">
                                Phone Number :{" "}
                                <span className="text-[16px]"> </span>
                              </h1>
                              <p className="font-all text-[#000000] overflow-text text-center lg:mt-[13px]">
                                {profiledata.mobileNumber}
                              </p>
                            </div>
                            <div className="lg:border-[1px] xs:p-[7px] lg:w-[25rem] border-[#D2D2D2] lg:rounded-md xs:border-y  lg:p-[8px] ">
                              <h1 className="intrest font-all txt-[#263238] overflow-text font-semibold text-[17px] flex flex-row items-center lg:gap-[11rem] ">
                                About Yourself :
                                <span className="text-[16px]"> </span>
                              </h1>
                              <p className="font-all text-[#000000] overflow-text text-center lg:mt-[13px]">
                                {profiledata.bio}
                              </p>
                            </div>
                          </div>
                          {/* cc */}
                          <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md p-3">
                            <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                              Inquiries :
                            </h1>{" "}
                            <div className="flex flex-row lg:mt-[26px] lg:mb-[13px] justify-center gap-4">
                              <Link to="/findproducts/inquiriesSent">
                                <button className="bg-[#F8F8F8] shadow-lg p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                                  Sent{" "}
                                  <span>
                                    <AiOutlineSend />
                                  </span>
                                </button>
                              </Link>

                              <Link to="/findproducts/inquiriesReceived">
                                <button className="bg-[#F8F8F8] shadow-md  p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                                  Received <MdCallReceived />
                                </button>
                              </Link>
                            </div>
                          </div>
                          {/* dd */}
                          <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md p-3">
                            {" "}
                            <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                              Adwords :
                            </h1>{" "}
                            <div className="flex justify-center items-center">
                              <Link to="/findproducts/adwords">
                                <button
                                  className="lg:mt-[1.4rem] bg-[#CB2229] shadow-md  p-[7px] w-[19rem] rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  
              text-white flex flex-row items-center gap-1 justify-center"
                                >
                                  Received{" "}
                                  <span>
                                    <BsArrowRight />
                                  </span>
                                </button>
                              </Link>
                            </div>
                          </div>

                          <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md ">
                            <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                              Bussiness card & Contact information :
                            </h1>{" "}
                            <div className="flex flex-row lg:mt-4 lg:mb-[31px] justify-center gap-4">
                              <Link to="/findproducts/requestedCard">
                                <button className="bg-[#F8F8F8] shadow-lg p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                                  Requested{" "}
                                  <span>
                                    <AiOutlineUserAdd />
                                  </span>
                                </button>
                              </Link>
                              <Link to="/findproduct/bussinessrequestReceived">
                                <button className="bg-[#F8F8F8] shadow-md  p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                                  Received <MdCallReceived />
                                </button>
                              </Link>
                            </div>
                            <div className="---2 flex flex-col justify-center items-center gap-2 lg:mb-[25px]">
                              <Link to="/findproduct/bussinessCreateCard">
                                <button className=" shadow-lg p-[5px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] text-white ">
                                  create bussiness card{" "}
                                </button>
                              </Link>

                              <button
                                onClick={() =>
                                  routeBussinessCard(profiledata.businessCardId)
                                }
                                className="text-black shadow-lg p-[5px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] border-[#CB2229] border-[1px] hover:text-white "
                              >
                                view card{" "}
                              </button>
                            </div>
                            {/*  */}
                          </div>
                          <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md ">
                            <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                              List of Services :
                            </h1>{" "}
                            <div className="flex flex-col items-center justify-center gap-3 lg:mt-[1.7rem]">
                              <Link to="/findproduct/viewallinput">
                                <button className=" shadow-lg lg:p-[6px] w-[20rem] rounded-md transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] border-[#CB2229] border-[1px] text-white flex flex-row items-center gap-3 justify-center lg:mt-4">
                                  view all input{" "}
                                  <span>
                                    <BsArrowRight />
                                  </span>
                                </button>
                              </Link>
                              {/*  */}
                              <Link to="/findproduct/createnewcampaign">
                                <button className="text-black shadow-lg p-[6px] w-[20rem] rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] border-[#CB2229] border-[1px] hover:text-white ">
                                  create new campaign
                                </button>
                              </Link>

                              {/*  */}
                            </div>
                          </div>
                        </div>

                        {/* fr  a  */}
                        <div className="lg:flex flex-row gap-4 lg:mt-4"></div>
                        {/* g  */}

                        <div className="flex flex-row justify-center gap-4 mt-4">
                          {/*  */}
                        </div>

                        <div className="border-[1px] border-[#D2D2D2] rounded-md mt-3  ">
                          <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                            List of Store :
                          </h1>
                          <Link to="/findproduct/viewregestration">
                            <button className="mx-auto mb-[2rem] mt-2 shadow-lg lg:p-[6px] w-[20rem] rounded-md transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] border-[#CB2229] border-[1px] text-white flex flex-row items-center gap-3 justify-center lg:mt-4">
                              view all regestration
                              <span>
                                <BsArrowRight />
                              </span>
                            </button>
                          </Link>
                        </div>

                        {/* h  */}
                        <div className="border-[1px] border-[#D2D2D2] rounded-md mt-4">
                          <div className="---a  flex fex-row justify-start ml-8 lg:gap-[20rem]">
                            <h1 className="intrest font-all text-[#263238] font-semibold text-[17px] p-[10px] ">
                              Post History :
                            </h1>{" "}
                          </div>
                          {/* user post history below */}
                          <div className="--product-decs">
                            <ProfilePostHistory profiledata={profiledata} />
                            {/* a */}
                          </div>
                        </div>
                        {/* i  */}

                        <div className="flex flex-col  sm:flex-row gap-4 mt-4 mb-4">
                          <Link to="/wallet">
                            <div className="--1 border-[1px] lg:w-[30rem] border-[#D2D2D2] rounded-md ">
                              <div className="text--desc flex fex-row justify-start lg:gap-[17rem]">
                                <h1 className="intrest font-all text-[#263238] font-semibold text-[17px] p-[10px] ">
                                  Wallet :
                                </h1>
                                <h1 className="intrest font-all text-[#263238]   p-[10px]  underline underline-offset-8 border-[#D2D2D2] ">
                                  view activity
                                </h1>{" "}
                              </div>

                              {/* <hr className="border-[1px] border-dashed border-[#D2D2D2]  " /> */}

                              <div
                                className="--wallet-desc flex flex-row items-center justify-center gap-16 mt-3
 "
                              >
                                <div className="--wallet-desc-&-balance flex flex-col ">
                                  <h6 className="font-all font-semibold text-[18px]">
                                    Current Balance
                                  </h6>
                                  <span>$XX.XX.X</span>
                                </div>
                                <img
                                  src={img}
                                  alt=""
                                  className="--wallet-img h-[10rem] xs:hidden block img-coin image-coin"
                                />
                              </div>
                            </div>
                          </Link>
                          {/* j  */}

                          <div className="border-[1px] rounded-md p-[12px]">
                            <div className="flex flex-col gap-3 lg:mt-[50px] xs:justify-center xs:items-center">
                              <Link to="/findproduct/cateogrizeProduct">
                                <button className="text-black shadow-lg p-[6px] w-[17rem] rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] border-[#CB2229] border-[1px] hover:text-white ">
                                  Cateogrize product market trends
                                </button>
                              </Link>
                              <Link to="/findproduct/corporateService">
                                <button className=" shadow-lg p-[6px] w-[17rem] rounded-md transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] border-[#CB2229] border-[1px] text-white flex flex-row gap-3 items-center justify-center">
                                  Corporate Services <BsArrowRight />
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* ed */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <EditProfile
              togglemode={handleButtonClick}
              profileDetails={profiledata}
              view={handleToggleMenu}
            />
          </>
        )}
      </div>
    </>
  );
}
