import React, { useState } from "react";

import { useWallet } from "../Context/WalletContext";
import { deposit_money } from "../../../api/userRegestration/CollectionForm";
import { NavLink, useNavigate } from "react-router-dom";
import { fetch_wallet_info } from "../../../api/userRegestration/CollectionForm";

import coin from "../avatarimg/coin.png";
import WalletsMoney from "./WalletsMoney";

function Addmoney() {
  const naviagte = useNavigate();
  // token from the local storage
  // const accessToken = localStorage.getItem("token");
  // this blocked scope is for displaying the wallet balance
  const { walletData } = useWallet();
  // next updating the amount with added money
  const { setWalletData } = useWallet();
  // handling the input field for depositing money in the wallet

  const [depositmoney, setdepositmoney] = useState({
    amount: "",
  });
  //
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setdepositmoney({ ...depositmoney, [name]: newValue });
  };
  //
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const deposit_value = {
        amount: depositmoney.amount,
      };
      const depositdata = await deposit_money(deposit_value);
      console.log("amount:", depositdata);

      fetch_wallet_balance();

      naviagte(`/wallet/userwallet/${depositmoney.amount}`);
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };
  const fetch_wallet_balance = () => {
    fetch_wallet_info()
      .then((response) => {
        console.log("API Response:", response);
        setWalletData(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="bg-layout drop-shadow-md lg:w-[152vh]   lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">
        <h1 className="font-bold text-[25px] ml-[2rem] relative top-[1rem]">
          Your Wallet
        </h1>

        <div className="flex flex-row xs:flex-col lg:gap-[9rem] mt-[2rem] mb-[2rem]">
          <div className="flex flex-col ">
            <div className="border-[1px] border-[#D2D2D2] h-[104px] rounded-md xs:w-[85%] xs:p-3 lg:w-[541px] ml-[2rem] mt-3">
              <div className="flex flex-col ml-[58px] mt-[1.3rem]">
                <h3 className="text-start font-all font-semibold inline ">
                  Current Balance
                </h3>
                <h5 className="font-bold text-[19px]">
                  {walletData.balance}{" "}
                  <span className="font-all text-sm">inr</span>{" "}
                </h5>
              </div>
            </div>

            <div className="form-input">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] xs:w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Add Money
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="amount"
                  className="input px-2 py-3 text-sm border-[1px] bg-white border-[#738482] rounded-md xs:mr-3 focus:border-[1px] p-[10px]"
                  value={depositmoney.amount}
                  onChange={handleInputChange}
                  aria-autocomplete="list"
                />
              </div>{" "}
            </div>

            <p className="font-all font-semibold  ml-[2.2rem] mt-3 text-[14px] mb-3">
              You can add upto 1000$
            </p>
            <div className="flex flex-row items-center xs:mr-3 gap-3 ml-[2.1rem]">
              <button className="bg-[#E6E6E6] rounded-full h-[33px] w-[173px] shadow-lg drop-shadow-md ">
                600$
              </button>
              <button className="bg-[#E6E6E6] rounded-full h-[33px] w-[173px] shadow-lg drop-shadow-md ">
                50$
              </button>
              <button className="bg-[#E6E6E6] rounded-full h-[33px] w-[173px] shadow-lg drop-shadow-md ">
                50$
              </button>
            </div>

            <p className="font-all font-semibold  ml-[2.2rem] mt-5 text-[14px]">
              Select Payment Method
            </p>

            <div className="flex flex-col gap-2 xs:mr-3 mt-[18px] ml-[32px] mb-4">
              <div className=" flex flex-row gap-3">
                <div className="       lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label className=" flex items-center space-x-2 justify-center  mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      className=" form-radio bg-transparent text-[#738482]"
                    />
                    <span className=" text-gray-700">Debit Card</span>
                  </label>
                </div>

                <div className="         lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6] border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label className=" flex items-center space-x-2  justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      className=" form-radio text-[#738482] bg-transparent"
                    />
                    <span className=" text-gray-700">Credit card</span>
                  </label>
                </div>

                <div className="        lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label className=" flex items-center space-x-2 justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      className=" form-radio text-[#738482] bg-transparent"
                    />
                    <span className=" text-gray-700">Net Banking</span>
                  </label>
                </div>
              </div>
              <div className=" flex flex-row gap-3">
                <div className="       lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label className=" flex items-center space-x-2  justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      className=" form-radio bg-transparent text-[#738482]"
                    />
                    <span className=" text-gray-700">Google pay</span>
                  </label>
                </div>

                <div className="         lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6] border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label className=" flex items-center space-x-2  justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      className=" form-radio text-[#738482] bg-transparent"
                    />
                    <span className=" text-gray-700">UPI</span>
                  </label>
                </div>

                <div className="        lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label className=" flex items-center space-x-2  justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      className=" form-radio text-[#738482] bg-transparent"
                    />
                    <span className=" text-gray-700">Bank Transfer</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="flex flex-col mt-[27px]">
            <img src={coin} className="h-[18rem] xs:hidden w-[276px] image-coin" alt="" />

            <div className=" button-action flex flex-col items-center lg:mt-[4rem] ">
              {/* <NavLink to='/wallet/userwallet'> */}
              <button
                onClick={handleSubmit}
                className="  bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
              >
                Proceed
              </button>
              {/* </NavLink> */}
             {/* <div className="hidden"> */}


{/* </div> */}
              <NavLink to="/wallet">
                <button className=" mb-6 border-[1px] xs:mt-3 xs:mb-12 border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                  Go Back
                </button>
              </NavLink>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}

export default Addmoney;
