import React from "react";
import { FaPlay } from "react-icons/fa";
function Helpmain() {
  return (
    <>
      <div className="swing-in-top-fwd">
        <div className="lg:container xs:w-[90%] xs:mb-28  bg-layout drop-shadow-md lg:w-[83%] mx-auto relative xs:left-0 left-[7rem] top-[1rem] ">
          <h1 className="text-center text-[22px] font-all font-extrabold mb-3 mt-1">
            Frequently Asked Question
          </h1>
          <section className="text-gray-600 body-font overflow-hidden">
            <div className="container px-5 mx-auto">
              <div className="-my-8">
                <div className="py-8 flex xs:flex-col-reverse flex-row-reverse items-center">
                  <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0  lg:h-[182px] flex  bg-[#757575] rounded-md">
                    <span className=" text-white bg-[#3631314f] flex items-center p-[19px] h-[3rem] m-auto rounded-full">
                      <FaPlay />
                    </span>
                  </div>
                  <div className="md:flex-grow">
                    <h2 className="text-2xl font-all text-gray-900 title-font mb-2 font-extrabold">
                      Q . How to Promote Inputs (adwords) ?
                    </h2>
                    <p className="leading-relaxed">
                      Glossier echo park pug, church-key sartorial biodiesel
                      vexillologist pop-up snackwave ramps cornhole. Marfa 3
                      wolf moon party messenger bag selfies, poke vaporware
                      kombucha lumbersexual pork belly polaroid hoodie portland
                      craft beer.
                    </p>
                    <span
                      style={{ fontWeight: "500" }}
                      className="text-black font-semibold inline-flex items-center mt-4"
                    >
                      video Link :{" "}
                      <span
                        classNmae="font-all "
                        style={{ fontWeight: "lighter" }}
                      >
                        mattis.com
                      </span>
                    </span>
                  </div>
                </div>
                <div className="py-8 flex flex-row-reverse xs:flex-col-reverse items-center">
                  <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0  lg:h-[182px] flex  bg-[#757575] rounded-md">
                    <span className=" text-white bg-[#3631314f] flex items-center p-[19px] h-[3rem] m-auto rounded-full">
                      <FaPlay />
                    </span>
                  </div>
                  <div className="md:flex-grow">
                    <h2 className="text-2xl font-all font-extrabold text-gray-900 title-font mb-2 rounded-md">
                      Q . How To Add Cateogry
                    </h2>
                    <p className="leading-relaxed">
                      Glossier echo park pug, church-key sartorial biodiesel
                      vexillologist pop-up snackwave ramps cornhole. Marfa 3
                      wolf moon party messenger bag selfies, poke vaporware
                      kombucha lumbersexual pork belly polaroid hoodie portland
                      craft beer.
                    </p>
                    <span
                      style={{ fontWeight: "500" }}
                      className="text-black font-semibold inline-flex items-center mt-4"
                    >
                      video Link :{" "}
                      <span
                        classNmae="font-all "
                        style={{ fontWeight: "lighter" }}
                      >
                        mattis.com
                      </span>
                    </span>
                  </div>
                </div>
                <div className="py-8 flex flex-row-reverse xs:flex-col-reverse items-center">
                  <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0  lg:h-[182px] flex  bg-[#757575] rounded-md">
                    <span className=" text-white bg-[#3631314f] flex items-center p-[19px] h-[3rem] m-auto rounded-full">
                      <FaPlay />
                    </span>
                  </div>
                  <div className="md:flex-grow">
                    <h2 className="text-2xl font-all font-extrabold text-gray-900 title-font mb-2">
                      Q . How to Add Fund In Wallet
                    </h2>
                    <p className="leading-relaxed">
                      Glossier echo park pug, church-key sartorial biodiesel
                      vexillologist pop-up snackwave ramps cornhole. Marfa 3
                      wolf moon party messenger bag selfies, poke vaporware
                      kombucha lumbersexual pork belly polaroid hoodie portland
                      craft beer.
                    </p>
                    <span
                      style={{ fontWeight: "500" }}
                      className="text-black font-semibold inline-flex items-center mt-4"
                    >
                      video Link :{" "}
                      <span
                        classNmae="font-all "
                        style={{ fontWeight: "lighter" }}
                      >
                        mattis.com
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Helpmain;
