import Sidebar from "../Chats/ChatComponets/Sidebar";
import Chat from "../Chats/ChatComponets/Chat";
import React, { useState } from "react";

function ChatWindow() {
  const [toggle, settoggle] = useState(false);

  return (
    <>
      <div
        className="lg:container md:container mx-auto xs:w-[90%]"
        style={{ marginTop: "-19px" }}
      >
        <div className="py-6 h-[90vh] xs:h-[79vh]">
          <div className="lg:flex md:flex border border-grey rounded shadow-lg h-full lg:ml-[15rem]">
            <div
              className={`lg:w-1/3 md:w-1/3 border flex flex-col    ${
                toggle ? "xs:hidden" : "xs:block"
              }        `}
            >
              <Sidebar settoggle={settoggle} />
            </div>
            <div
              className={`lg:w-2/3 px-1 border flex flex-col ${
                toggle ? "block" : "xs:hidden"
              }`}
            >
              <Chat settoggle={settoggle} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatWindow;
