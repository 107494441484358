import React, { useEffect, useState } from "react";
import { getAllSellerjobList } from "../../../../../api/userRegestration/CollectionForm";
import { useNavigate } from "react-router-dom";
export default function JobsNear() {
  const accessToken = localStorage.getItem("token");
  const [job, setjob] = useState([]);

  useEffect(() => {
    getAllSellerjobList(accessToken)
      .then((reult) => {
        setjob(reult);
        console.log("hello 12232om90----",reult);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accessToken]);
  //

  const navigate = useNavigate();

  const handleroute = (jobId) => {
    navigate(`/findjobs/Job_Buyer/Job_details/${jobId}`);
  };
  //
  return (
    <>
      <div
        className="flex items-center justify-center mt-10 rounded w-[93%]
          mx-auto p-[9px] bg-gray-50 "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="font-all font-bold text-start -auto text-[21px] lg:mt-[27px] mb-6">
            Jobs Near You{" "}
          </h1>
          <div className="grid grid-cols-2 xs:grid-cols-1 gap-4">
            {job.map((item) => (
              <div
                className="flex w-full max-w-[48rem] bg-white rounded-xl bg-clip-border text-gray-700 shadow-md xs:flex-col"
                key={item._id}
              >
                <div className="m-0 w-1/2 xs:w-auto overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700">
                  <img
                    src={
                      item.mediaUrl ||
                      "https://img.freepik.com/free-vector/gradient-no-photo-sign-design_23-2149288321.jpg?w=740&t=st=1698336396~exp=1698336996~hmac=5902909a3f01faa62af1109bce51fdfddd5fc9974b7638d56c796eabd7b4274f"
                    }
                    alt="not found"
                    className="h-full w-full object-cover"
                    onError={(e) => {
                      e.target.src =
                        "https://img.freepik.com/free-vector/gradient-no-photo-sign-design_23-2149288321.jpg?w=740&t=st=1698336396~exp=1698336996~hmac=5902909a3f01faa62af1109bce51fdfddd5fc9974b7638d56c796eabd7b4274f";
                    }}
                  />
                </div>

                <div className="p-6 w-1/2">
                  {/* <h6 className="mb-4 block font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-pink-500 antialiased">
          verified
        </h6> */}
                  <h4 className="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                    {item.profession}{" "}
                    <span className="font-light whitespace-nowrap text-[13px]">
                      ({item.sector})
                    </span>
                  </h4>
                  <div className="mb-8 block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased italic">
                    {item.description.split(" ").slice(0, 7).join(" ")}...
                  </div>
                  <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                    {item.skills.split(",").map((skill) => (
                      <label key={skill}>
                        <input
                          type="radio"
                          name="type"
                          value={skill.trim()} // Display the individual skill
                          className="peer sr-only "
                          defaultChecked=""
                        />
                        <p className="bg-black text-white rounded-lg border border-black font-bold font-all text-[10px] p-2 mb-4">
                          {skill.trim()}
                        </p>
                      </label>
                    ))}
                    <span className="font-light whitespace-nowrap text-[13px] mx-auto ">
                      exprience :({item.experience})
                    </span>
                  </div>

                  <div className="flex flex-row xs:flex-col item-center">
                    <button
                      className="flex select-none items-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-gray-500 transition-all hover:bg-gray-500/10 active:bg-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      {item.salary}
                    </button>
                    <button
                      onClick={() => handleroute(item._id)}
                      className="flex select-none items-center text-center gap-2 rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-gray-500 transition-all bg-gray-500/10  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      view
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <h1 className=" mt-2 cursor-pointer underline-offset-4 text-center font-all    hover:text-blue-600 w-[12%] mx-auto">
            See more
          </h1>
        </div>
      </div>
    </>
  );
}
