import React, { useState } from "react";

export default function MultiSelectDropdown() {
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
    { value: "option4", label: "Option 4" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div className="relative">
          <div className="flex flex-wrap">
            {selectedOptions.map((option) => (
              <div
                key={option.value}
                className="m-1 bg-blue-200 px-2 py-1 rounded-full flex items-center"
              >
                <span className="mr-1">{option.label}</span>
                <button
                  onClick={() => toggleOption(option)}
                  className="focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-blue-500 hover:text-blue-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 1C5.03 1 1 5.03 1 10s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm4.95 13.364a.75.75 0 1 1-1.06 1.06L10 11.06l-4.89 4.89a.75.75 0 1 1-1.06-1.06L8.94 10 4.05 5.11a.75.75 0 1 1 1.06-1.06L10 8.94l4.89-4.89a.75.75 0 1 1 1.06 1.06L11.06 10l4.89 4.89z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
          <input
            className="w-full py-2 pl-3 pr-10 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            type="text"
            value={selectedOptions.map((option) => option.label).join(", ")}
            onClick={toggleDropdown} // Toggle dropdown on input click
          />
          {dropdownVisible && (
            <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
              {options.map((option) => (
                <li
                  key={option.value}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => toggleOption(option)}
                >
                  <input
                    type="checkbox"
                    className="mr-2 cursor-pointer"
                    checked={selectedOptions.includes(option)}
                    readOnly
                  />
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
