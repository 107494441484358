import React, { useEffect, useState } from "react";
import { activecampaignuser } from "../../../../../../api/userRegestration/Campaign";
import {
  all_campaign_list,
  click_on_promotion,
} from "../../../../../../api/userRegestration/Campaign";
import { useNavigate } from "react-router-dom";

function ActiveCampaigns() {
  const [categories, setCategories] = useState([]);
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [campaignlist, setCampaignList] = useState([]);
  useEffect(() => {
    activecampaignuser(accessToken)
      .then((result) => {
        setCategories(result);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  useEffect(() => {
    all_campaign_list(accessToken)
      .then((result) => {
        setCampaignList(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);


  const handleRoute = (promotionId, type) => {
    console.log(promotionId);
   
    click_on_promotion(promotionId)
      .then((response) => {
        console.log("id ", response);
      })
      .catch((err) => {
        console.error("error", err);
      });
      navigate(`/featuredproduct/productdetail_/${promotionId}?type=${type}`);
 
     };

  return (
    <>
      <div className="">
        <table className="lg:min-w-full xs:m-auto overflow-scroll">
          <tbody>
            {categories.map((category, index) => (
              <tr
                key={index}
                className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {index + 1}-
                </td>

                <td className="text-[17px] font-all font-bold px-6 py-4 whitespace-nowrap">
                  {category.headline} 
                </td>

                <td className="text-[17px] font-all font-bold px-6 py-4 whitespace-nowrap">
                  {category.duration}
                </td>

                <td className="text-[17px] font-all font-bold px-6 py-4 whitespace-nowrap">
                  {category.clicks}
                </td>

                <td className="text-xs font-all font-light px-6 py-4 whitespace-nowrap">
                  {new Date(category.createdAt).toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZoneName: "short",
                  })}
                </td>
                <td className="text-[17px] font-all font-bold px-6 py-4 whitespace-nowrap">
                  <button onClick={() => handleRoute(category._id, "active_campaign")}>View</button>
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ActiveCampaigns;
