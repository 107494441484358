import React from "react";
import { FaArrowLeft } from "react-icons/fa";
export default function Catalouge_Offer_Form({
  settogglecatalouge,
  formData,
  handleCatalogueChange,
}) {
  return (
    <>
      <div className="p-2">
        <h3
          className=" my-2 text-sm flex items-center cursor-pointer font-all font-semibold"
          onClick={() => {
            settogglecatalouge(false);
          }}
        >
          <FaArrowLeft className="mr-2" /> Go back to Store Registration
        </h3>
        <h1 className="text-xl text-center my-4 font-all font-semibold">
          Create catalogue
        </h1>
        <div className="__catalouge_fields">
          <div className="flex flex-col justify-center items-center">
            {/* name  */}
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                name
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="catalogue-name-0"
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                value={formData.catalogue[0].name}
                onChange={(e) => handleCatalogueChange(e, 0, "name")}
              />
            </div>
            {/* price */}
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Price
              </label>
              <input
                type="number"
                placeholder="Write here..."
                name="catalogue-price-0"
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                value={formData.catalogue[0].price}
                onChange={(e) => handleCatalogueChange(e, 0, "price")}
              />
            </div>
            {/* description */}
            <div className="cooltextarea flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-4">
              <label
                htmlFor="textarea"
                className="text text-sm text-black w-max rounded-md bg-white relative top-2.5 ml-2 px-1 w-fit-content"
              >
                Description
              </label>
              <textarea
                placeholder="Write here..."
                name="catalogue-description-0"
                id="catalogue-description-0"
                rows="6"
                className="textarea px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                value={formData.catalogue[0].description}
                onChange={(e) => handleCatalogueChange(e, 0, "description")}
              />
            </div>
            <span 
               onClick={() => {
                settogglecatalouge(false);
              }}
            
            className=" cursor-pointer bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem] flex flex-row  justify-center items-center gap-2  transform hover:scale-105 duration-500 ease-in-out relative">
          create catalouge
          </span>
          </div>
       
        </div>
      </div>
    </>
  );
}
