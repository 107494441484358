import React from "react";
import Logo from "../assets/logo/Logo-Trans.png";
import { MdNotificationsActive } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { Link } from "react-router-dom";

function MobileSnackBar() {
  return (
    <>
      <>
        {/* component */}
        <nav className=" bg-gray-100  top-0 w-full flex  justify-end items-center  gap-[3rem] mx-auto px-8 h-20">
          {/* logo */}
          <div className="inline-flex">
            <img src={Logo} className="h-[45px] mx-auto" alt="" />
          </div>
          {/* end logo */}
          <div className="flex flex-row gap-2">
            <MdNotificationsActive className="text-[25px] text-gray-500 " />
            <Link to={"/ResellerDashboard"}>
              <BsGraphUpArrow className="text-[25px] text-gray-500 " />
            </Link>
          </div>

          {/* end login */}
        </nav>
      </>
    </>
  );
}

export default MobileSnackBar;
