import React, { useEffect, useState } from "react";
import PromotedInfo from "./PromotedInfo";
import { list_promoted } from "../../../../../../api/userRegestration/PromotionResponse";

const tabsData = [
  {
    label: "Product",
    type: "PRODUCT",
  },
  {
    label: "Service",
    type: "SERVICE",
  },
  {
    label: "Jobs",
    type: "JOB",
  },
  {
    label: "Post",
    type: "POST",
  },
  {
    label: "Project",
    type: "PROJECT",
  },
];

function AdwordsTabs() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    list_promoted(accessToken)
      .then((result) => {
        setCategories(result);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <>
      <div className=" lg:mb-12 relative top-[1rem] swing-in-top-fwd xs:m-auto">
        <h1 className="font-all font-semibold text-center text-[24px] mt-4 lg:mb-12">
          Promotion List
        </h1>

        <div className=" p-6 px-0 pt-0 pb-2">
          <div className="flex justify-center gap-[5rem] xs:gap-[1rem] space-x-3 xs:space-x-1 border-b">
            {/* Loop through tab data and render button for each. */}
            {tabsData.map((tab, idx) => (
              <button
                key={idx}
                className={`font-all border-b-4 py-2 text-[19px] font-semibold transition-colors duration-300 ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                onClick={() => handleTabClick(idx)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="w-full lg:min-w-[640px] table-auto mt-6 overflow-scroll">
            <span>
              <PromotedInfo
                data={categories}
                selectedType={tabsData[activeTabIndex].type}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdwordsTabs;
