import React, { useEffect, useState } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Rating } from "flowbite-react";
import { getAllSellerProducts } from "../../../../../api/userRegestration/CollectionForm";

import { useNavigate } from "react-router-dom";
//
export default function ProductForYou() {
  //
  const accessToken = localStorage.getItem("token");
  const [Product, setProduct] = useState([]);
  const scrollLeft = () => {
    document.getElementById("contentB").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("contentB").scrollLeft += 400;
  };
  const [showIcon1, setShowIcon1] = useState(true);
  const toggleIcon = () => {
    setShowIcon1(!showIcon1);
  };

  //
  useEffect(() => {
    getAllSellerProducts(accessToken)
      .then((result) => {
        setProduct(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  const navigate = useNavigate();
  //
  const handleroute = (productId) => {
    navigate(`/findproducts/products_for_you_/product_detail_/${productId}`);
  };
  return (
    <>
      <div className="relative">
        <div className="flex flex-row items-center"></div>
        <h1 className="text-start font-all font-extrabold text-[#263238] text-[19px] xs:mt-[6rem] flex flex-row mt-[15%]">
          Product Seller{" "}
          <div className=" flex absolute xs:hidden right-[15%]">
            <button
              onClick={scrollLeft}
              className="relative  bg-[#0000002e]  p-2 m-2 rounded-full carousel-bg"
            >
              <FiChevronLeft className="relative lg:right-[1px] text-black" />
            </button>
            <button
              onClick={scrollRight}
              className=" relative  bg-[#0000002e]  p-2 m-2 rounded-full "
            >
              <FiChevronRight className="relative text-black" />
            </button>
          </div>
        </h1>

        <div
          id="contentB"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1 xs:px-[7%]"
        >
          {Product.map((item) => (
            <>
              <>
                <link
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
                  rel="stylesheet"
                />
                <div
                  onClick={() => handleroute(item._id)}
                  key={item._id}
                  className="antialiased text-gray-900 "
                >
                  <div className=" p-2 flex items-center justify-center">
                    <div className="bg-white rounded-lg overflow-hidden shadow-md  border_product_card w-[16rem] xs:w-[14rem]">
                      {/*E11*/}
                      {/* <div className=" h-48 bg-cover bg-center" style="background-image:url('https://images.unsplash.com/photo-1570797197190-8e003a00c846?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=968&q=80')"></div>*/}
                      <img
                        className="h-48 w-full object-cover object-end"
                        src={item.mediaUrl}
                        alt="Home in Countryside"
                      />
                      <div className="p-6">
                        <div className="flex items-baseline">
                          <div className="ml-2 text-gray-600 text-xs  tracking-wide">
                            <span className="bg-gray-200 rounded-full p-1 uppercase  font-semibold">
                              MQU
                            </span>{" "}
                            {item.mqu} ,{" "}
                            <span className="bg-gray-200 rounded-full p-1 uppercase  font-semibold">
                              Moq
                            </span>{" "}
                            {item.moq}
                          </div>
                        </div>
                        <h4 className="mt-2 font-semibold text-lg leading-tight truncate">
                          {item.productName}
                        </h4>
                        <div className="mt-1">
                          <span>{item.amount}</span>
                          <span className="text-gray-600 text-sm ml-1">
                            OMR
                          </span>
                        </div>
                        <div className="mt-2 flex items-center">
                          <span className="text-teal-600 font-semibold">
                            <span>
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="far fa-star" />
                              <span></span>
                              <span className="ml-2 text-gray-600 text-sm">
                                34 reviews
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
