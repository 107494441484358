import React from "react";
import { Link } from "react-router-dom";

export default function CorprateService() {
  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left lg:h-[100vh]">
        <h1 className=" mt-12 text-center font-all font-semibld text-[23px] font-semibold">
          Corporate Service
        </h1>

        <div className="flex flex-col gap-8 ml-[8rem] mt-[4rem]">
          <div className="--a">
            <h1 className="font-all font-semibold mb-3">
              a) Promotional email
            </h1>
            <span>
              .Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo
              fugiat saepe hic!
            </span>
          </div>

          <div className="--b">
            <h1 className="font-all font-semibold mb-3">b) SMS</h1>
            <span>
              .Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo
              fugiat saepe hic!
            </span>
          </div>

          <div className="--c">
            <h1 className="font-all font-semibold mb-3">c) Other Services</h1>
            <span>
              .Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo
              fugiat saepe hic!
            </span>
          </div>
        </div>

        <div className="text mx-aut ml-8 ">
          <p className="font-all mx-aut font-semibold text-[14px] mt-[5rem] ">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
            alias nisi excepturi quam quod reiciendis quibusdam dignissimos
            veniam corporis repudiandae facere, commodi vero expedita illo saepe
            debitis, eligendi at?
          </p>
        </div>

        <div className="flex flex-col justiy-center items-center mt-[4rem]">
          <p className="font-all">To know more on service , kindly </p>

          <Link to="/contact-us">
            <p className="text-center font-semibold text-[24px] cursor-pointer">
              Contact US{" "}
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}
