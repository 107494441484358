import React, { useState } from 'react'
import AllCampaigns from './AllCampaigns';
import ActiveCampaigns from './ActiveCampaigns';

function CampaignStock() {

const [activeTabIndex, setActiveTabIndex] = useState (0);

    const tabsData = [
        {
          label: "All Campaigns",
          content: <AllCampaigns/>,
        },
        {
          label: "Active Campaigns",
          content: <ActiveCampaigns/>,
        },
        
        
      ];
  return (
   <>
   <div className="  p-2  mb-[7rem]   relative top-[1rem] fade-in-left ">
        <div className=" ml-4 flex space-x-3 border-b justify-center gap-[5rem]">
          {/* Loop through tab data and render button for each. */}
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Show active tab content. */}
        <div className=" ">
          <p>{tabsData[activeTabIndex].content}</p>
        </div>
      </div>
   </>
  )
}

export default CampaignStock