import React, { useState } from "react";

import {
  AiOutlinePlusCircle,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { create_promotion } from "../../../../api/userRegestration/Campaign";
export default function CreateCampaignForm() {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [suggestedKeywords, setSuggestedKeywords] = useState("");
  const [location, setLocation] = useState("");
  const [sliderValue, setSliderValue] = useState(90);
  const [spinner, setspinner] = useState(false);
  const [campaginformData, setcampaginformData] = useState({
    headline: "",
    duration: "",
    bidAmount: "",
    photo: "",
    description: "",
    keyword: "",
    budget: "",
    startdate: "",
  });

  const handleiputchange = (event) => {
    const { name, value } = event.target;
    setcampaginformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlesubmit = async (e) => {
    console.log(new Date().toLocaleTimeString());

    setspinner(true);
    try {
      const formData = {
        headline: campaginformData.headline,
        duration: campaginformData.duration,
        bidAmount: campaginformData.bidAmount,
        photo:
          "https://images.unsplash.com/photo-1533167649158-6d508895b680?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8c3BsYXNofGVufDB8fDB8fHww",
        description: campaginformData.description,
        keyword: [campaginformData.keyword],
        budget: campaginformData.budget,
        startDate: new Date().getTime(),
      };
      const campaignformdata = await create_promotion(formData);
      console.log(campaignformdata, "campaign");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSliderChange = (e) => {
    setSliderValue(parseInt(e.target.value));
  };
  //
  return (
    <>
      <div className=" w-[93%]  mx-auto p-[5px] xs:p-0 xs:mb-20">
        <div className="border border-[#cfcaca82] rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm">
          <h1 className="text-center font-all xs:text-2xl font-semibold my-[12px] text-[19px]">
            Create a Campaign
          </h1>
          <form
            onSubmit={handlesubmit}
            className="flex flex-col justify-center items-center"
          >
            {/* item a  */}
            <div className="item-a xs:w-full">
              <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add headline
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="headline"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                  value={campaginformData.headline}
                  onChange={handleiputchange}
                />
              </div>
              {/* b  */}
              <div className="coolinput flex flex-col w-fit-content static  lg:w-[32rem] w-auto ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add Description
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="description"
                  value={campaginformData.description}
                  onChange={handleiputchange}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/* c  text-area */}
              <div className="collinpu-text-area--img  flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  img field here
                </label>
                <div className="mb-4  h-[9rem] input px-2 py-3 text-sm border-[1px] border-[#738482] border-dashed rounded-md focus:border-[1px]">
                  <span className="p-[40px] text-[42px] flex justify-center items-center text-[#D2D2D2]">
                    <AiOutlinePlusCircle />
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="item-b  mt-4 mb-2 xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm mb-3 ">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Select Keywords
            </h1>
            {/* - a  */}
            <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Add Keywords
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="keyword"
                value={campaginformData.keyword}
                onChange={handleiputchange}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
            {/* - b  */}
            <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-3 ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Suggested Keywords
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                value={suggestedKeywords}
                onChange={(e) => setSuggestedKeywords(e.target.value)}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
          </div>
        </div>
        <div className="item-c mt-4 mb-2 xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm mb-3 ">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Advertise Near Location
            </h1>
            {/*  */}
            <div className="--get-location drop-c flex flex-col justify-center rounded-full mx-auto p-[9px] w-[14rem]">
              <span className="flex flex-row items-center justify-center gap-2">
                <GrLocation /> Use Current Location
              </span>
            </div>
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mx-auto ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Location
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
            <div className="w-3/4 mx-auto mt-4 mb-4">
              <label
                htmlFor="range"
                className=" font-all  text-lg font-semibold"
              >
                Selected Distance ( {sliderValue} km):
              </label>
              <input
                type="range"
                id="range"
                name="range"
                min="20"
                max="500"
                value={sliderValue}
                onChange={handleiputchange}
                className="w-full mt-2 bg-yellow-200"
              />
              <div className="flex justify-between mt-2">
                <span>20 km</span>
                <span>{sliderValue} km</span>
              </div>
            </div>

            {/*  */}
          </div>
          <div className="item-d setbudget border border-[#cfcaca82]  xs:w-full  rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 className=" font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Add Budget
            </h1>
            {/* a  */}
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static xs:w-full xs:p-2 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Enter your Budget
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  value={campaginformData.budget}
                  onChange={handleiputchange}
                  name="budget"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              <div className="coolinput flex flex-col w-fit-content static xs:w-full xs:p-2 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Max Bidding amount
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  name="bidAmount"
                  value={campaginformData.bidAmount}
                  onChange={handleiputchange}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/* b  */}
              <div className="flex flex-row  xs:gap-2 items-center justify-start gap-[10rem] ">
                <h1 className="font-all font-semibold flex flex-col">
                  {" "}
                  <span>Current balance</span>
                  200.97$
                </h1>
                <button className=" bg-[#cac5c585] shadow-md lg:mx-auto xs:m-2 p-[6px] xs:w-[8rem] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  add money
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="--item-d --run add-money xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md xs:w-full w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              How long will u take to run this ad
            </h1>
            <div className="flex flex-col items-center justify-center gap-[9px] xs:w-[90%]  ">
              <div className="flex flex-row items-center gap-2 xs:w-[90%]">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                />

                <div className="coolinput flex flex-col w-fit-content xs:w-full static lg:w-[32rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max  rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Set date
                  </label>
                  <input
                    type="number"
                    placeholder="Write here..."
                    name="duration"
                    value={FormData.duration}
                    onChange={handleiputchange}
                    className="input px-2 py-3 text-sm border-[1px] xs:w-full border-[#738482] rounded-md focus:border-[1px]"
                  />
                </div>
              </div>

              <hr className="w-full" />
              <div className="flex flex-row items-center relative xs:right-0 right-[11rem] gap-3">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="checked-radio"
                  className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                />
                <h6 className="font-all font-semibold gap-3">
                  until the fund = 0
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="--item-c--input-form-velow-section xs:w-[99%]">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm mb-3 mt-4">
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  VPA Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={vpaAddress}
                  // onChange={(e) => setVpaAddress(e.target.value)}
                  className="input px-2 py-3 xs:w-[99%] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Billing Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 xs:w-[99%] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={country}
                  // onChange={(e) => setCountry(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Street Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={streetAddress}
                  // onChange={(e) => setStreetAddress(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  City
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={city}
                  // onChange={(e) => setCity(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  state / province
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={stateProvince}
                  // onChange={(e) => setStateProvince(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Postal Code
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={postalCode}
                  // onChange={(e) => setPostalCode(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Tax Status
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={taxStatus}
                  // onChange={(e) => setTaxStatus(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              <div className="collinpu-text-area--img  flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem]">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Billing Address & Text Information
                </label>
                <div className="mb-4  h-[9rem] input px-2 py-3 text-sm border-[1px] border-[#738482] border-dashed rounded-md focus:border-[1px]">
                  <span className="p-[40px] text-[42px] flex justify-center items-center text-[#D2D2D2]">
                    <AiOutlinePlusCircle />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={handlesubmit}
          className=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center"
        >
          Confirm and Make Payment
        </button>
      </div>
    </>
  );
}
