import React, { useRef } from "react";
import Logo from "../../../../assets/logo/Logo-Trans.png";
import image from "../../../../assets/logo/auth.png";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Reseller_otp } from "../../../../api/userRegestration/CollectionForm";
// import ResellerBUtton from '../../../../component/ResellerBUtton'
import { useState } from "react";
import { useWallet } from "../../../Wallet/Context/WalletContext";
const ResellerVerifyPhone = () => {
  const token = localStorage.getItem("token");
  const { email } = useParams();

  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const navigate = useNavigate();
  const setresellerview = useWallet();



  const handleInputChange = (e, index) => {
    const newValue = e.target.value;

    // Check if the entered value is a number between 1 and 9
    if (/^[0-9]$/.test(newValue)) {
      const updatedValues = [...inputValues];
      updatedValues[index] = newValue;
      setInputValues(updatedValues);

      // Automatically move focus to the next input field
      if (index < inputRefs.length - 1 && newValue.length === 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };
  const handleBackspace = (index) => {
    // If the current input field is already empty, move focus to the previous field
    if (inputValues[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else {
      // Remove the value in the current field
      const updatedValues = [...inputValues];
      updatedValues[index] = "";
      setInputValues(updatedValues);
    }
  };
  
  const handleKeyDown = (e, index) => {
    // Check if the Backspace key is pressed
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent browser navigation
      handleBackspace(index);
    }
  };
  
  const concatenateInputs = async () => {
    const concatenated = inputValues.join("");

    try {
      const result = await Reseller_otp(email, concatenated, token);
      console.log(result, token);
      navigate("/register/reseller/Become_Reseller");
      setresellerview(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
      <div className="md:hidden lg:hidden   flex flex-col justify-center items-center mt-12 xs:mt-8 gap-[12px]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134px] xs:h-[49px] fade-in  "
              />

<h4 className=" text-center font-all font-semibold xs:text-center">
               Verify Your email address
              </h4>


            </div>
        <div className="flex flex-row justify-start  ">

          <div className="xs:hidden  item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-7 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-5">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                verify your email address by otp
              </p>
            </div>
            <div className="image relative top-[10px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[3rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
     
          <div className="item-2 xs:bg-[#cb22290f] fade-in w-[50%] xs:mx-auto flex xs:w-[89%] xs:mt-8 ">
            <div className="bg-white xs:bg-transparent shdaow-md w-[88%] rounded-md  lg:mt-[4rem]  mx-auto h-[552px] lg:drop-c fade-in-right xs:h-max">
              <h1 className="text-all text-center font-bold text-[22px]  lg:relative top-[24px]">
                Verify OTP
              </h1>
              <h3 className="text-center text-[#000000] lg:mt-[36px]">
                Waiting to automatic detect an SMS sent to email here
              </h3>
              <p className="text-[#8C8C8C] text-center mt-[14px]">
                Wrong email?
              </p>

              <div className="input-flex-row flex flex-row gap-[20px] justify-center mt-[13px]">
                {inputValues.map((value, index) => (
                 <input
                 key={index}
                 ref={inputRefs[index]}
                 className="w-[40px] focus:border-mont-serif appearance-none py-2 px-3 bg-white dark:bg-white text-gray-700 leading-tight focus:outline-none border border-gray-400 rounded-md"
                 type="text"
                 maxLength="1"
                 name={`field${index + 1}`}
                 id={`field${index + 1}`}
                 value={value}
                 onChange={(e) => handleInputChange(e, index)}
                 onKeyDown={(e) =>
                  e.key === "Backspace" && handleKeyDown(e,index)
                }
               />
                ))}
              </div>
              {/* <hr className="border border-[#738482] mt-[4.25rem] w-[50%] mx-auto m-auto " /> */}

              <p className="text-[#8C8C8C] text-center mt-[18px]">
                Enter 4 Digit code
              </p>

              <p className="text-center text-[12px] mt-[50px] underline  underline-offset-2">
                Didn’t receive code?
              </p>

              <div className="flex flex-col gap-3 mt-[40px]">
                <button className="  flex justify-center items-center mx-auto m-auto border-[1px] border-[#CB2229] text-black rounded-full font-all p-[2px]  w-[15rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  send again in 0:04
                </button>

                {/* <Link to="/" > */}
                <button
                  onClick={concatenateInputs}
                  className="xs:mb-8  flex justify-center items-center mx-auto m-auto border-[1px] bg-[#CB2229] text-white rounded-full font-all p-[2px]  w-[15rem]   transform hover:scale-105 duration-500 ease-in-out "
                >
                  next
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResellerVerifyPhone;
