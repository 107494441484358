import React from "react";
import { useNavigate } from "react-router-dom";
//
import { AiOutlineCheck } from "react-icons/ai";
//
function NotificationModel({ setnotificationmodal }) {
  const navigate = useNavigate();
  const handle = () => {
    setnotificationmodal(false);
    window.scrollTo(0,0);
    navigate("/");
  };
  //
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade-in-right  backdrop-blur-sm">
        <div className="">
          {/*content*/}
          <div className="border-2 border-gray-300 drop-shadow-lg rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className=" p-2 border-b border-solid border-blueGray-200 rounded-t bg-gray-100">
              <h3 className="text-[19px] flex justify-center font-semibold  font-all  ">
                UNDER VERIFICATION
              </h3>
              <button className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"></button>
            </div>
            {/*body*/}
            <div className="relative p-2 px-4 flex-auto">
              <div className="item_091 rounded-full p-2 flex justify-center relative mt-3 content-center">
                <AiOutlineCheck className="text-[60px] rounded-full border-2" />
              </div>
              <div className="my-4 flex justify-center">
                <div className="flex flex-row justify-center items-center gap-[2.5rem]">
                  <div className=" button-action flex flex-col items-center mt-6 gap-3">
                    <h3 className="font-all font-semibold text-lg">
                      Your Submission request has been received !
                    </h3>
                    <p className="font-all font-semibold text-gray-500 text-[15px]">
                      Please wait while the admin view your detail
                    </p>

                    <button
                      onClick={handle}
                      className="  mt-4   bg-[#CB2229] flex flex-row justify-center content-center items-center gap-2 text-white rounded-full font-all p-[4px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                    >
                      Okay ! dismiss notification
                    </button>

                    {/* <Link to="/create_module_promotion" > */}

                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationModel;
