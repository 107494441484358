import React, { useState } from 'react'
import AdwordsTabs from '../../home/ServicesByINO/FindProduct/Profile/Adwords/AdwordsTabs';
import CampaignStock from '../../home/ServicesByINO/FindProduct/Profile/Adwords/CampaignStock';

export default function AdwordStock() {
const [activeTabIndex, setActiveTabIndex] = useState(0);

    const tabsData = [
        {
          label: "Promotions",
          content: <AdwordsTabs />,
        },
        {
          label: "Campaign",
          content: <CampaignStock/>,
        },
        
        
      ];
  return (
    <>
    <div className="  bg-layout shadow-md drop-shadow-md lg:w-[80%] p-2  mb-[7rem]  lg:ml-[17%] relative top-[1rem] fade-in-left ">
        <div className=" ml-4 flex space-x-3 border-b justify-center gap-[5rem]">
          {/* Loop through tab data and render button for each. */}
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Show active tab content. */}
        <div className=" ">
          <p>{tabsData[activeTabIndex].content}</p>
        </div>
      </div>
    </>
  )
}
