import React, { useEffect, useState } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import {
  all_campaign_list,
  click_on_promotion,
} from "../../api/userRegestration/Campaign";
import { useNavigate } from "react-router-dom";

function CampaignListing() {
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [campaignlist, setCampaignList] = useState([]);

  const scrollLeft = () => {
    document.getElementById("contentB").scrollLeft -= 400;
  };

  const scrollRight = () => {
    document.getElementById("contentB").scrollLeft += 400;
  };

  useEffect(() => {
    all_campaign_list(accessToken)
      .then((result) => {
        setCampaignList(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);

  const handleRoute = (promotionId,type) => {
    console.log(promotionId); 

    click_on_promotion(promotionId)
      .then((response) => {
        console.log("id ", response);
      })
      .catch((err) => {
        console.error("error", err);
      });

    navigate(`/featuredproduct/productdetail_/${promotionId}?type=${type}`);
  };

  return (
    <>
      <div className="relative">
        <div className="flex flex-row items-center"></div>
        <h1 className="text-start font-all font-extrabold text-[#263238] text-[19px] xs:mt-[6rem] flex flex-row ">
          Featured Product{" "}
          <div className=" flex absolute xs:hidden right-[15%]">
            <button
              onClick={scrollLeft}
              className="relative  bg-[#0000002e]  p-2 m-2 rounded-full carousel-bg"
            >
              <FiChevronLeft className="relative lg:right-[1px] text-black" />
            </button>
            <button
              onClick={scrollRight}
              className=" relative  bg-[#0000002e]  p-2 m-2 rounded-full "
            >
              <FiChevronRight className="relative text-black" />
            </button>
          </div>
        </h1>

        <div
          id="contentB"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1 xs:px-[7%]"
        >
          {campaignlist.map((item) => (
            <div
              onClick={() => handleRoute(item._id,"featured_product")}
              key={item._id}
              className="antialiased text-gray-900 "
            >
              <div className="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
                <span
                  className="relative mx-3  flex h-60 overflow-hidden rounded-xl"
                  
                >
                  <img
                    className="object-cover w-full"
                    src={item.photo}
                    alt="product image"
                  />
                  <span className="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
                    Featured
                  </span>
                </span>
                <div className="mt-4 px-5 pb-5">
                  <a href="#">
                    <h5 className="text-xl font-all font-semibold tracking-tight text-slate-900">
                      {item.headline}
                    </h5>
                  </a>
                  <div className="mt-2 mb-5 flex items-center justify-between">
                    <p>
                      <span className="text-xl font-all text-slate-900">
                        {item.budget}omr
                      </span>
                    </p>
                    <div className="flex items-center">
                      {[...Array(5)].map((_, index) => (
                        <svg
                          key={index}
                          aria-hidden="true"
                          className="h-5 w-5 text-yellow-300"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                      <span className="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
                        5.0
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CampaignListing;
