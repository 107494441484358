import React from "react";
import { BsPencil } from "react-icons/bs";
export default function CampaignProductDetails() {
  return (
    <>
      <div className="flex flex-row xs:flex-col  p-[13px] gap-4">
        {/* .---a  */}
        <div className="--img-bannerh-[286px] rounded-md w-[485px] xs:w-[400px]">
          <img
            className="h-[255px] rounded-md xs:w-[400px] w-[457px] drop-shadow-md

            "
            src="https://img.freepik.com/free-vector/people-buying-food-supermarket-concept-illustration_114360-13391.jpg?w=1060&t=st=1689522881~exp=1689523481~hmac=e0de18e0af97ad0b2909606d31c9d9165fd55301a76e17b83bea131a1a498929"
            alt=""
          />
        </div>
        {/* .---b  */}
        <div className="flex flex-col mt-[17px]">
          <h1 className="text-[#000000] font-all font-semibold xs:text-2xl text-[19px]">
            Buyer Input
          </h1>

          <div className="flex flex-col b mt-[12px] gap-[7px]">
            <div className="flex flex-row-items-center lg:gap-[17rem] xs:border-y-0  border-y">
              <h4 className="font-all font xs:text-xl">Product Categories</h4>
              <span className="flex flex-row items-center font-all">
                <span>
                  <BsPencil />
                </span>{" "}
              </span>
            </div>
            {/*  */}
            <div className="flex flex-row-items-center lg:gap-[20rem] xs:border-b-0  border-b">
              <h4 className="font-all font xs:text-xl">Product Rate</h4>
              <span className="flex flex-row items-center font-all">
                <span>
                  <BsPencil />
                </span>{" "}
              </span>
            </div>
            {/*  */}
            <div className="flex flex-row-items-center lg:gap-[14.2rem] xs:border-y-0  border-y">
              <h4 className="font-all font xs:text-xl">Minimum Order Quanity</h4>
              <span className="flex flex-row items-center font-all">
                <span>
                  <BsPencil />
                </span>{" "}
              </span>
            </div>
            {/*  */}
            <div className="flex flex-row-items-center lg:gap-[22.3rem] xs:border-b-0  border-b">
              <h4 className="font-all font xs:text-xl">MQ Unit</h4>
              <span className="flex flex-row items-center font-all">
                <span>
                  <BsPencil />
                </span>{" "}
              </span>
            </div>
            {/*  */}
            <div className="flex flex-row-items-center lg:gap-[15rem] xs:border-y-0 border-y">
              <h4 className="font-all font xs:text-xl">Detailed Requirements</h4>
              <span className="flex flex-row items-center font-all">
                <span>
                  <BsPencil />
                </span>{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
