import React from "react";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../../../../../Wallet/Context/WalletContext";

const PromotedInfo = ({ data, selectedType }) => {
  const navigate = useNavigate();
  const { profiledata } = useWallet();

  const handleRoute = (item) => {
    const { type, jobId, productId, projectId, postId, serviceId } = item;

    switch (type) {
      case "PRODUCT":
        navigate(
          `/findproducts/adwords/promotionlistpage_/${productId}`
        );
        break;
      case "SERVICE":
        navigate(`/findproducts/adwords/promotionlistpage_/${serviceId}`);
        break;
      case "JOB":
        navigate(`/findproducts/adwords/promotionlistpage_/${jobId}`);
        break;
      case "PROJECT":
        navigate(`/findproducts/adwords/promotionlistpage_/${projectId}`);
        break;
      case "POST":
        navigate(`/post/${postId}`);
        break;
      default:
        // Handle other types or provide a default route
        break;
    }
  };
  //
  // {profiledata._id}

  return (
    <>
      <div className="">
        <table className="lg:min-w-full xs:m-auto overflow-scroll">
          <tbody>
            {data
              .filter((category) => category.type === selectedType)
              .filter((category) => category.ownerId === profiledata._id) // Add this filter condition
              .map((category, index) => (
                <tr
                  key={category._id}
                  className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                >
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {category.ownerId}
                  </td> */}

                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {index + 1}-
                  </td>

                  {/* <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    {category.bidAmount}{" "}
                    <span className="font-light text-sm">inr</span>
                  </td> */}

                  {/* <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    {category.duration}
                  </td> */}

                  <td className=" text-base  font-all font-light px-6 py-4 whitespace-nowrap">
                    {category.keyword}
                  </td>

                  <td className=" text-xs  font-all font-light px-6 py-4 whitespace-nowrap">
                    {new Date(category.createdAt).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    })}
                  </td>

                  <td className=" flex flex-row gap-3 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <Button
                      onClick={() => handleRoute(category)}
                      className=" border-2 border-black px-5 bg-slate-300 text-black"
                    >
                      View 
                    </Button>

                    {/* <Button
                      onClick={() => handleRoute(category)}
                      className="bg-red-600 text-white"
                    >
                      view module info
                    </Button> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PromotedInfo;
