import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getAllSellerServiceList } from "../api/userRegestration/CollectionForm";

function ReusbaleMobileCard() {
  const accessToken = localStorage.getItem("token");
  const [Product, setProduct] = useState([]);
  const [showIcon1, setShowIcon1] = useState(true);
  const navigate = useNavigate();
  //

  useEffect(() => {
    getAllSellerServiceList(accessToken)
      .then((result) => {
        setProduct(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);

  //
  return (
    <>
      <div className="w-[40vh] xs:mb-[6rem]">
        <div className="relative">
        <h1 className="text-start font-all font-extrabold text-[#263238] mt-3 text-[19px] ml-4">
         Services
        </h1>

          <div
            id="contentB"
            className="carousel p-4 px-[7%] flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
          >
            {Product.map((item) => (
              <>
                <>
                  <link
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
                    rel="stylesheet"
                  />

                  <div key={item._id} className="antialiased text-gray-900 ">
                    <div className=" p-2 flex items-center justify-center">
                      <div className="bg-white rounded-lg overflow-hidden shadow-md w-[14rem] border_product_card">
                        {/*E11*/}
                        {/* <div className=" h-48 bg-cover bg-center" style="background-image:url('https://images.unsplash.com/photo-1570797197190-8e003a00c846?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=968&q=80')"></div>*/}
                        <img
                          className="h-48 w-full object-cover object-end"
                          src={item.mediaUrl}
                          alt="Home in Countryside"
                        />
                        <div className="p-[4%]">
                          <h4 className="mt-2 font-semibold text-lg leading-tight truncate">
                            {item.serviceName}
                          </h4>
                          <div className="mt-1">
                            <span>{item.rate}</span>
                            <span className="text-gray-600 text-sm ml-1">
                              OMR
                            </span>
                          </div>
                          <div className="mt-2 flex items-center">
                            <span className="text-teal-600 font-semibold">
                              <span>
                                <i className="fas fa-star" />
                                <i className="fas fa-star" />
                                <i className="fas fa-star" />
                                <i className="fas fa-star" />
                                <i className="far fa-star" />
                                <span></span>
                                <span className="ml-2 text-gray-600 text-sm">
                                  34 reviews
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReusbaleMobileCard;
