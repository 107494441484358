import React, { useEffect, useState } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Rating } from "flowbite-react";
import {getAllBuyerProducts} from "../../../../../api/userRegestration/CollectionForm"

import { Navigate, useNavigate } from "react-router-dom";
// 
export default function AddProjectProductsCarousel() {
  const accessToken = localStorage.getItem("token");
  const [Product, setProduct] = useState([]);
  const [showIcon1, setShowIcon1] = useState(true);

  const scrollLeft = () => {
    document.getElementById("contentB").scrollLeft -= 400;
  };

  const scrollRight = () => {
    document.getElementById("contentB").scrollLeft += 400;
  };

  const toggleIcon = () => {
    setShowIcon1(!showIcon1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllBuyerProducts(accessToken);
        setProduct(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [accessToken]);
const navigate = useNavigate()


const handleroute = (productId) => {
  navigate(`/findproducts/products_for_you_/product_detail_/${productId}`);
};
  return (
    <>
      <div className="relative">
        <h1 className="text-start font-all font-extrabold text-[#263238] mt-3 lg:text-[19px]">
         Products
        </h1>

        <div
          id="contentB"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
        >
       {Product.map((item)=>(

<>
<>
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
    rel="stylesheet"
  />
  <div   onClick={() => handleroute(item._id)}  key={item._id}  className="antialiased text-gray-900 ">
    <div className=" p-2 flex items-center justify-center">
      <div className="bg-white rounded-lg overflow-hidden shadow-md w-[16rem]">
        {/*E11*/}
        {/* <div className=" h-48 bg-cover bg-center" style="background-image:url('https://images.unsplash.com/photo-1570797197190-8e003a00c846?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=968&q=80')"></div>*/}
        <img
          className="h-48 w-full object-cover object-end"
          src={item.mediaUrl}
          alt="Home in Countryside"
        />
        <div className="p-6">
          <div className="flex items-baseline">
            <span className="inline-block bg-teal-200 text-teal-800 py-1 px-4 text-xs rounded-full uppercase font-semibold tracking-wide">
              New
            </span>
            <div className="ml-2 text-gray-600 text-xs uppercase font-semibold tracking-wide">
            MQu  {item.mqu} • Moq {item.moq}
            </div>
          </div>
          <h4 className="mt-2 font-semibold text-lg leading-tight truncate">
          {item.productName}
          </h4>
          <div className="mt-1">
            <span>{item.amount}</span>
            <span className="text-gray-600 text-sm ml-1">OMR</span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="text-teal-600 font-semibold">
              <span>
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <i className="far fa-star" />
                <span></span>
                <span className="ml-2 text-gray-600 text-sm">34 reviews</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

</>


))}
        </div>
      </div>
    </>
  );
}
