import React from 'react'
import Map from './Map'
import Markettrend from './Markettrend'

function MapStock() {
  return (
    <>
    <div className="item-web xs:hidden">
    <div className="flex flex-col gap-3">
      <Map/>
      <Markettrend/>
      </div>
      </div>
  
    </>
  )
}

export default MapStock
