import React, { useEffect, useState } from "react";

import { SendReferalCode } from "../../api/userRegestration/CollectionForm";

function Referotp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    referalcode: "",
  });
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    try {
      const Referal_data = {
        referralCode: formData.referalcode,
      };
      const Job_data = await SendReferalCode(Referal_data);
      console.log("j", Job_data);
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };

  return (
    <>
      <div className="fade-in-left flex flex-col items-center border-[1px] border-[#D2D2D2] xs:p-2  rounded-md lg:p-[28px] lg:mt-[4rem] lg:mb-[7rem]">
        <h1 className=" font-all font-extrabold text-[23px] text-color lg:relative mb-4">
          Use Your Code !
        </h1>

        <form onSubmit={handlesubmit}>
          <div className="text-top">
            <h6 className="font-all text-center  text-[#000000]">
              Receive A verify Code and Enter Below
            </h6>

            {/* <div className="text-referal">
            <h3 className="text-[#000000] text-center font-all  mt-1">
              Here is you Referal link
            </h3>
            <h4 className="text-[#4460A0] fot-all relative text-center mt-1">
              https://www.dummylinkINO.com
            </h4>
          </div> */}
            <div className="qr-code">
              <h3 className="text-[#000000] font-all text-center mt-1">
                {" "}
                Type Your code Below{" "}
              </h3>
              <div className="icong-image flex justify-center ">
                {/* <img
                src="https://i.pinimg.com/564x/cd/c7/3b/cdc73bef50d55c07511f10fbf67fa47c.jpg"
                className="lg:h-[8pc]  rounded-md"
                alt=""
              /> */}

                <div className="shadow-lg font-all font-semibold p-[20px] drop-shadow border rounded-md my-6 text center">
                  <div>
                    <input
                      type="text"
                      name="referalcode"
                      value={formData.referalcode}
                      onChange={handleInputChange}
                      className="border-b-2 focus:border-none bg-white text-center border-dased border-black"
                    />
                  </div>
                </div>
              </div>
              <div className="button-action flex flex-col xs:mb-28 items-center ">
                <button
                  type="submit"
                  className=" mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  "
                >
                  Enter code
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Referotp;
