import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyBb5DcMIewZAFd9pOOx7ZVFOUy-wf5lfH0",
//   authDomain: "lamachat-252e8.firebaseapp.com",
//   projectId: "lamachat-252e8",
//   storageBucket: "lamachat-252e8.appspot.com",
//   messagingSenderId: "552738939343",
//   appId: "1:552738939343:web:4cc731010bfc0f4ea732ba"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDZn0rkpaRltOGGUEhFOl5a9544VRyZIAk",
//   authDomain: "e-ino-bddf7.firebaseapp.com",
//   projectId: "e-ino-bddf7",
//   storageBucket: "e-ino-bddf7.appspot.com",
//   messagingSenderId: "714701807985",
//   appId: "1:714701807985:web:995e20b16370bc4c5796d0",
//   measurementId: "G-DX47SDK891"
// };
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA5KCdt7FrXmc4phnt23XcCnpl605EAMuc",
  authDomain: "dummyproject-76083.firebaseapp.com",
  projectId: "dummyproject-76083",
  storageBucket: "dummyproject-76083.appspot.com",
  messagingSenderId: "97026024876",
  appId: "1:97026024876:web:f94470bd5c9eeb41f90609",
  measurementId: "G-KKNQ0G0H6D"
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
