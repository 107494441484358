import Homeslider from "../../../HomePageMainComponent/Homeslider";
// import GovProjectSlider from "./GovProjectSlider";
import GovProjects from "./GovProjects";
import OtherProjects from "./OtherProjects";

export default function GovMain() {
  return (
    <>
      <div className="gov gov-main main-div">
        <div className=" m-2 xs:overflow-hidden">
          <div className=" flex items-center justify-center h-48 mb-8 ">
          <Homeslider/>
          </div>
          <div
            className=" mt-[13rem] xs:mt-2 flex items-center justify-center h-48 xs:h-auto rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh] xs:w-[95%] xs:m-auto xs:h-auto">
              <GovProjects />
            </div>
          </div>
          <div
            className=" mt-[2rem]  flex items-center justify-center xs:h-auto h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh] xs:w-[95%] xs:m-auto">
              <OtherProjects />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
