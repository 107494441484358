import React from "react";
import { Link, useNavigate } from "react-router-dom";


export default function InquiriesAllSent() {
  const navigate = useNavigate();
const handleclick=()=>{
  navigate("/findproducts/inquiriesSent/user")
}
  return (
    <div className="bg-layout drop-shadow-md lg:w-[152vh] mb-12 lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">
      <h1 className="font-all font-semibold text-center text-[24px] mt-4 mb-12">
        Inquiries Sent
      </h1>

      <div className="--content--inquiries">
        <div className="lg:w-[85%] lg:mx-auto flex flex-col lg:gap-[4px]">
          <div className="">
            <table className="lg:min-w-full xs:m-auto overflow-scroll">
              <tbody>
                <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    1-
                  </td>
                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    headline{" "}
                  </td>
                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    22
                  </td>
                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    0
                  </td>
                  <td className=" text-xs  font-all font-light px-6 py-4 whitespace-nowrap">
                    Monday, March 18, 2024 at 2:39:11 AM GMT+5
                  </td>
                  <td className=" text-black  font-all font-light px-6 py-4 whitespace-nowrap">
                    <button onClick={handleclick}>View</button>
                  </td>
                </tr>
                <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    2-
                  </td>
                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    headline{" "}
                  </td>
                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    22
                  </td>
                  <td className=" text-[17px]  font-all font-bold px-6 py-4 whitespace-nowrap">
                    0
                  </td>
                  <td className=" text-xs  font-all font-light px-6 py-4 whitespace-nowrap">
                    Monday, March 18, 2024 at 2:39:20 AM GMT+5
                  </td>
                  <td className=" text-black font-all font-light px-6 py-4 whitespace-nowrap">
                    <button onClick={handleclick}>View</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="button--action--reverse flex justify-center">
        <Link to={"/userProfile"}>
          <button className="my-[2rem] bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  border-[1px] border-[#CB2229] hover:text-white flex flex-row items-center justify-center gap-2">
            Go Back
          </button>
        </Link>
      </div>
    </div>
  );
}
