import React, { useEffect, useState } from "react";

import { fetch_Profile_Status } from "../../api/userRegestration/CollectionForm";

export default function ChangeNumber() {
  const [profile, setProfile] = useState();

  useEffect(() => {
    fetch_Profile_Status()
      .then((response) => {
        console.log("API Response:", response);
        setProfile(response.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
  <>
  
  <div className="p-4 s">

<div className="p-4 sm:ml-64 bg-layout">
<main className="w-full max-w-md mx-auto p-6">
  <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm shark:bg-gray-800 shark:border-gray-700">
    <div className="p-4 sm:p-7">
      <div className="text-center">
        <h1 className="block text-2xl font-bold text-gray-800 shark:text-white">
        Change Phone Number
        </h1>
      
      </div>
      <div className="mt-5">
     
        <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 shark:text-gray-500 shark:before:border-gray-600 shark:after:border-gray-600">
     
        </div>
        {/* Form */}
        <form>
          <div className="grid gap-y-4">
        
            <div>
              <label
                htmlFor="password"
                className="block text-sm mb-2 shark:text-white"
              >
                Previous Phone number
              </label>

              {profile && (
<>
<div className="relative">
              <input
  type="text"
  id="first_name"
  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-blue-500 shark:focus:border-blue-500"
  placeholder="923347590766"
  value={profile.mobileNumber}
/>

                <div className="hidden absolute inset-y-0 end-0  items-center pointer-events-none pe-3">
                  <svg
                    className="h-5 w-5 text-red-500"
                    width={16}
                    height={16}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                  </svg>
                </div>
              </div>
</>
)}
             
              <p
                className="hidden text-xs text-red-600 mt-2"
                id="password-error"
              >
                8+ characters required
              </p>
            </div>
            {/* End Form Group */}
            {/* Form Group */}
            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm mb-2 shark:text-white"
              >
             New Phone Number
              </label>
              <div className="relative">
              <input
  type="text"
  id="first_name"
  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-blue-500 shark:focus:border-blue-500"
  placeholder="new number type here "
  required=""
/>
                <div className="hidden absolute inset-y-0 end-0 items-center pointer-events-none pe-3">
                  <svg
                    className="h-5 w-5 text-red-500"
                    width={16}
                    height={16}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                  </svg>
                </div>
              </div>
              <p
                className="hidden text-xs text-red-600 mt-2"
                id="confirm-password-error"
              >
                Password does not match the password
              </p>
            </div>
            {/* End Form Group */}
            {/* Checkbox */}
            <div className="flex items-center">
              <div className="flex">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 shark:bg-gray-800 shark:border-gray-700 shark:checked:bg-blue-500 shark:checked:border-blue-500 shark:focus:ring-offset-gray-800"
                />
              </div>
              <div className="ms-3">
                <label
                  htmlFor="remember-me"
                  className="text-sm shark:text-white"
                >
                  I am sure that i 
                  <a
                    className="text-blue-600 decoration-2 hover:underline font-medium shark:focus:outline-none shark:focus:ring-1 shark:focus:ring-gray-600"
                    href="#"
                  >
                   am updating my phone number from e-INO
                  </a>
                </label>
              </div>
            </div>
            {/* End Checkbox */}
            <button
              type="submit"
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-[#cb2229] text-white  disabled:opacity-50 disabled:pointer-events-none shark:focus:outline-none shark:focus:ring-1 shark:focus:ring-gray-600"
            >
          Update phone number
            </button>
          </div>
        </form>
        {/* End Form */}
      </div>
    </div>
  </div>
</main>


</div>


  </div>
  
  </>
  )
}
