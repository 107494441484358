import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { FaInstagram,FaTwitterSquare,FaYoutube  } from "react-icons/fa";
import { AiFillFacebook} from "react-icons/ai";
function Contactplace() {
  return (
    <>
      <div className="flex flex-col gap-3 fade-in-right">
        <div className=" border-[1px] border-[#D2D2D2] rounded-md flex flex-col gap-3 items-center 
        h-[195px] w-[350px]">
          <h1 className=" text-center font-all text-[19px] font-bold lg:mt-[16px]">
            Reach us at{" "}
          </h1>
          <div className=" cursor-pointer  transform hover:scale-105 duration-500 hover:rounded-md ease-in-out border-[1px]  bg-[#F0EAEA] p-[9px] w-[17rem]">
            <h1 className="font-all">
              <FaPhoneAlt className="inline text-[#738482] ml-[14px]" />{" "}
              <span
                style={{ textDecoration: "underline" }}
                className=" font-all font-semibold  text-[#616565]"
              >
                +91218975
              </span>{" "}
            </h1>
          </div>

          <div className="border-[1px]   bg-[#F0EAEA] p-[9px] w-[17rem] cursor-pointer  transform hover:scale-105 duration-500 hover:rounded-md ease-in-out ">
            <h1 className="font-all ">
              <GoMail className="text-[21px] inline text-[#738482] ml-[14px]" />{" "}
              <span
                style={{ textDecoration: "underline" }}
                className=" font-all font-semibold  text-[#4460A0]"
              >
                dummyemail@email.tech
              </span>{" "}
            </h1>
          </div>
        </div>

        <div className="  border-[1px] border-[#D2D2D2]  rounded-md flex flex-col xs:mb-28  gap-3 items-center md:h-[195px] h-max w-[350px] md:mb-0 mb-8">
          <h1 className=" text-center font-all text-[19px] font-bold lg:mt-[16px]">
            Contact via Social Media :
          </h1>
          <div className="flex flex-row items-center gap-3 mt-6 mb-4">
            <div className="facebook text-[40px]  cursor-pointer transform hover:scale-105 duration-500 hover:rounded-md ease-in-out  text-[#445c8f]  hoverd ">
              <AiFillFacebook />
            </div>
         
            <div className="insta text-[39px] cursor-pointer transform hover:scale-105 duration-500 hover:rounded-md ease-in-out hoverd-insta  text-[#E1306C]">
              <FaInstagram />
            </div>

            <div className="twitter text-[39px] cursor-pointer transform hover:scale-105 duration-500 hover:rounded-md ease-in-out  text-[#1DA1F2] rounded-md">
              <FaTwitterSquare />
            </div>

            <div className="yotube text-[39px] cursor-pointer transform hover:scale-105 duration-500 hover:rounded-md ease-in-out  text-[#FF0000]">
              <FaYoutube />
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactplace;
