import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchSProductDetails } from "../../../../api/userRegestration/CollectionForm";
import UserChatButton from "../../../../Widgets/UserChatButton";
import { ChatContext } from "../../../../Context/ChatContext";
import Loader from "../../../../component/Loader";

export default function PromotionListPage() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { receiverId, setReceiverId } = useContext(ChatContext);
  const [loading, setloading] = useState(true);
  const [productdetails, setproductdetails] = useState();
  const Home = () => {
    navigate(`/`);
  };

  // const location = useLocation();

  const handleClick = () => {
    navigate(`/`);
  };

  useEffect(() => {
    fetchSProductDetails(productId)
      .then((response) => {
        console.log("API Response:", response);
        setproductdetails(response.result);
        setloading(false);
        setReceiverId(response.result.creatorId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [productId]);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md  mb-[7rem] xs:w-auto xs:ml-auto  ml-[17%] w-[81%] relative top-[1rem]">
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          productdetails && (
            <>
              <div className=" flex flex-col items-center justify-center ">
                <section className=" w-[50%] border border-gray-200 my-2 ">
                  <div className=" p-2 gap-3">
                    <h1 className=" text-3xl font-semibold text-black sm:text-3xl flex  justify-center  xs:text-2xl font-sans">
                      Promote Inputs
                    </h1>

                    <h2 className="mt-4 text-lg  text-black xs:text-lg border-b font-semibold font-sans">
                      Select Keywords :{" "}
                      <span className="italic  font-normal">
                        {" "}
                        {productdetails.keyword}{" "}
                      </span>
                    </h2>
                    {/*  */}
                    {/* Product rate  */}
                    <h2 className="mt-4 text-lg text-black xs:text-lg border-b font-semibold font-sans">
                      Budget :{" "}
                      <span className="italic  font-normal">
                        {" "}
                        {productdetails.budget}{" "}
                      </span>
                    </h2>
                    {/* minimum order quanity  */}
                    <h2 className="mt-3 text-lg text-black xs:text-lg  border-b font-semibold font-sans">
                      Maximum Bidding Amount :{" "}
                      <span className="italic  font-normal">
                        {" "}
                        {productdetails.bidAmount}{" "}
                      </span>
                    </h2>
                    <h2 className="mt-3 text-lg text-black xs:text-lg border-b font-semibold font-sans">
                      Duration :{" "}
                      <span className="italic font-light ">
                        {" "}
                        {productdetails.duration}{" "}
                      </span>{" "}
                    </h2>
                    <h2 className="mt-3 text-lg text-black xs:text-lg border-b font-semibold font-sans">
                      Product Details :
                      <span className="italic  font-normal">
                        {" "}
                        " {productdetails.details} "
                      </span>
                    </h2>
                  </div>
                  
                </section>
                <section className=" w-[50%]  border border-gray-200 my-2 ">
                  <div className=" p-2 gap-3">
                    <h1 className=" text-3xl font-semibold text-black sm:text-3xl flex  justify-center  xs:text-2xl font-sans">
                      Promote Inputs
                    </h1>

                    <h2 className="mt-4 text-lg  text-black xs:text-lg border-b font-semibold font-sans">
                      Select Keywords :{" "}
                      <span className="italic  font-normal">
                        {" "}
                        {productdetails.keyword}{" "}
                      </span>
                    </h2>
                    {/*  */}
                    {/* Product rate  */}
                    <h2 className="mt-4 text-lg text-black xs:text-lg border-b font-semibold font-sans">
                      Budget :{" "}
                      <span className="italic  font-normal">
                        {" "}
                        {productdetails.budget}{" "}
                      </span>
                    </h2>
                    {/* minimum order quanity  */}
                    <h2 className="mt-3 text-lg text-black xs:text-lg  border-b font-semibold font-sans">
                      Maximum Bidding Amount :{" "}
                      <span className="italic  font-normal">
                        {" "}
                        {productdetails.bidAmount}{" "}
                      </span>
                    </h2>
                    <h2 className="mt-3 text-lg text-black xs:text-lg border-b font-semibold font-sans">
                      Duration :{" "}
                      <span className="italic font-light ">
                        {" "}
                        {productdetails.duration}{" "}
                      </span>{" "}
                    </h2>
                    <h2 className="mt-3 text-lg text-black xs:text-lg border-b font-semibold font-sans">
                      Product Details :
                      <span className="italic  font-normal">
                        {" "}
                        " {productdetails.details} "
                      </span>
                    </h2>
                  </div>
                  
                </section>
                
                
              </div>
            
            </>
          )
        )}
      </div>
      
    </>
  );
}
