import React from "react";

function Licensestock() {
  return (
    <>
      <div className="swing-in-top-fwd">
        <div className=" lg:container xs:w-[90%]   bg-layout drop-shadow-md lg:w-[83%] mx-auto relative xs:left-0 left-[7rem] top-[1rem] lg:h-[77vh]">
          <div className="  lg:w-[83%] mx-auto xs:m-2 xs:mb-28 ">
            <h1 className=" text-center text-[23px] mt-1 relative  lg:top-[2rem] font-extrabold font-all title-font mb-4 text-gray-900">
              {" "}
              Licenses
            </h1>

            <p className="font-all mt-[5rem] ">
              Welcome to our website and thank you for taking the time to review
              our website license policy. By accessing and using our website,
              you are agreeing to the terms and conditions of this policy.
            </p>

            <p className="font-all mt-4">
              We may collect personal information voluntarily provided by you
              through our website, including your name, email address, and phone
              number. We take your privacy seriously and will not sell, rent or
              share your personal information with third parties for their own
              marketing purposes.
            </p>

            <p className="font-all mt-4 ">
              In addition, we may use cookies and other tracking technologies to
              improve the functionality and performance of our website, analyze
              website usage, and personalize your experience on our website.
              Please note that our website may contain links to third-party
              websites and services outside of our control. We recommend that
              you read their privacy policies before accessing or using them.
            </p>

            <p className="font-all mt-4 ">
              Rest assured that we take reasonable measures to protect the
              personal information we collect through our website. If you have
              any questions or concerns about this website license policy,
              please feel free to contact us via [contact information].
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Licensestock;
