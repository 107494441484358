import { useEffect, useState } from "react";
import {
  Findjob,
  job_categories,
} from "../../../../../api/userRegestration/CollectionForm";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../../../component/Spinner";
import PromotionFormBody from "../../../../PromotionForm/PromotionFormBody";
import { TbSpeakerphone } from "react-icons/tb";
import PromotionPopup from "../../../../../Widgets/PromotionPopup";
import NotificationModel from "../../../../../Widgets/NotificationModel";
export default function PostInputMain() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const accessToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [promotebutton, setPromoteButton] = useState(true);
  const [getserviceId, setgetserviceId] = useState();
  const [notificationmodal, setnotificationmodal] = useState(!true);
  const handleButtonClick = () => {
    toast.success("you are now at promotion form!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setPromoteButton(!promotebutton);
  };
  const handleInputChanges = (event) => {
    setSearchInput(event.target.value);
  };

  //
  //
  //

  const toggenotifcation = () => {
    setnotificationmodal(true);
  };
  const dimissnotification = () => {
    console.log("clicked");
    setnotificationmodal(false);

    setShowModal(true);
  };
  //
  //
  //
  const [formData, setFormData] = useState({
    title: "",
    ProefessionCategories: "",
    SalaryExpectation: "",
    YearsOfExpriece: "",
    Resume: "",
    ABoutYourself: "",
    ReleaventSkills: "",
  });
  //
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file); // Convert and store the image as Base64
    } else {
      setSelectedImage(null);
      setImageBase64(null); // Clear the Base64 data if no image is selected
    }
  };
  //
  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };
  //
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const job_data = {
        title: formData.title,
        sector: "nothing",
        profession: selectedCategory,
       
        description: formData.ABoutYourself,
        salary: formData.SalaryExpectation,
        
        experience: formData.YearsOfExpriece,
        type: "SELLER",
        skills: formData.ReleaventSkills,
      };
      const execute_job = await Findjob(job_data);
      console.log("post:", execute_job);
      console.log(" ID FROM THE PARENT COMPONENT ", execute_job.result);

      console.log(
        "request  FROM THE PARENT COMPONENT ",
        execute_job.result.saveRequest.jobId
      );

      let serviceId;

      setgetserviceId(execute_job.result.saveRequest.jobId);
      console.log("id capsuled under state ");

      if (
        execute_job &&
        execute_job.result &&
        execute_job.result.saveRequest.jobId
      ) {
        serviceId = execute_job.result.saveRequest.serviceId;

        setgetserviceId((serviceId) => {
          console.log("previous job Id ", serviceId);
          return serviceId;
        });

        console.log(
          "\x1b[32m%s\x1b[0m",
          "product id from scattered state",
          serviceId
        );
      } else {
        console.log(" ID not found in the response.");
      }
      console.log("job", execute_job);

      // toaster
      toast.success("  hitted  successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setShowModal(true);
    } catch (error) {
      console.error("Error in :", error.message);
    } finally {
      setLoading(false);
    }
  };
  //
  useEffect(() => {
    job_categories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);

  //
  useEffect(() => {
    if (getserviceId) {
      console.log("Product ID from state:", getserviceId);
    }
  }, [getserviceId]);
  //
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {promotebutton ? (
        <>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row xs:flex xs:flex-col lg:gap-[9rem] mt-[3rem] mb-[2rem]">
                {/* 1 */}
                <div className="form-input flex flex-col xs:w-[90%] xs:mx-auto  w-[47%]">
                  <h1 className="font-all font-semibold text-center">
                    Job Seller
                  </h1>
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  lg:lg:ml-[2.1rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="title"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.title}
                      onChange={handleInputChange}
                      required
                    />
                  </div>{" "}
                  {/* <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  "> */}
                    <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] lg:ml-[2.2rem] ">
                      <label
                        htmlFor="select"
                        className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 lg:ml-[2.1rem] px-1 bg-w-fit-content text-center"
                      >
                        Profession Categories
                      </label>
                      <input
                        type="text"
                        placeholder="Write here..."
                        name="category"
                        className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                        value={selectedCategory} // Use the selectedCategory state here
                        onChange={handleInputChange}
                        onFocus={toggleDropdown}
                        required
                      />
                    </div>
                    {/* <button onClick={toggleDropdown}>Toggle Dropdown</button> */}
                    {isOpen && (
                      <ul
                        className="absolute h-96 overflow-scroll z-10  xs:w-96 w-[19rem]  ml-12 mt-[4rem] bg-white border rounded-md shadow-md  slide-in-blurred-top list-disc"
                        style={{ scrollBehavior: "smooth" }}
                      >
                        <label
                          className="mx-auto  bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center  py-2 px-2  gap-2 shadow-2xl focus-within:border-gray-300 sticky top-0"
                          htmlFor="search-bar"
                        >
                          <input
                            id="search-bar"
                            placeholder="your keyword here or add "
                            className="px-6  w-full flex-1 outline-none bg-white"
                            onChange={handleInputChanges}
                            value={searchInput}
                          />
                        </label>

                        {categories
                          .filter((category) =>
                            category.name
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())
                          )
                          .map((category) => (
                            <li
                              key={category._id}
                              onClick={() => {
                                setIsOpen(!isOpen);
                                setSelectedCategory(category.name); // Update the selected category
                              }}
                              className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 `}
                            >
                              {category.name}
                            </li>
                          ))}
                      </ul>
                    )}
                  {/* </div>{" "} */}
                  {/* 2  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  lg:ml-[2.1rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Salary Expectation
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="SalaryExpectation"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.SalaryExpectation}
                      onChange={handleInputChange}
                      required
                      onKeyPress={(event) => {
                        // Allow only digits (0-9) and backspace
                        const keyCode = event.keyCode || event.which;
                        const keyValue = String.fromCharCode(keyCode);
                        const regex = /[0-9\b]/;
                        if (!regex.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>{" "}
                  {/* 3 */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  lg:ml-[2.1rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      years of exprience
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="YearsOfExpriece"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.YearsOfExpriece}
                      onChange={handleInputChange}
                      required
                    />
                  </div>{" "}
                  {/* 4  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  lg:ml-[2.1rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Resume / CV
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                    />
                  </div>{" "}
                  {/* 5  */}
                  {/* text area  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] lg:ml-[2.1rem]">
                    <label
                      htmlFor="input"
                      className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      About Yourself
                    </label>
                    <textarea
                      placeholder="Write here..."
                      name="ABoutYourself"
                      rows="7"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.ABoutYourself}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  {/*  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] lg:ml-[2.1rem]">
                    <label
                      htmlFor="input"
                      className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Relevant Skills / Keywords
                    </label>
                    <textarea
                      placeholder="Write here..."
                      name="ReleaventSkills"
                      rows="7"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.ReleaventSkills}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  {/*  */}
                  <div className="add-attachment flex flex-row items-center gap-3 ml-[3rem] mt-4">
                    <span className="font-all text-[35px]">+</span>{" "}
                    <span className="font-all">add attachment</span>
                  </div>
                </div>

                {/* 2 */}
                <div className="flex flex-col mt-8">
                  {/* <div className="flex items-center justify-center w-full">
  <label
  style={{
    display: selectedImage ? 'none' : 'block'}}
  
    htmlFor="dropzone-file"
    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
  >
    <div className="flex flex-col items-center justify-center pt-5 pb-6">
      <svg
        className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
      <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
        <span className="font-semibold">Click to upload</span> or drag and drop
      </p>
      <p className="text-xs text-gray-500 shark:text-gray-400">
        SVG, PNG, JPG, or GIF (MAX. 800x400px)
      </p>
    </div>
    <input
      id="dropzone-file"
      type="file"
      className="hidden"
      onChange={handleImageChange}
    />
  </label>
  {selectedImage && (
    <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
   
      <img
      className="w-full h-44 mx-auto "
        src={selectedImage}
        alt="Selected"
      
      />
    </div>
  )}
</div> */}
                  <div className=" flex items-center xs:w-[90%] xs:mx-auto">
                    <input
                      id="link-checkbox"
                      type="checkbox"
                      value=""
                      className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                    />
                    <label
                      for="link-checkbox"
                      className=" ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
                    >
                      I agree to share my Bussiness card with Supplier .
                    </label>
                  </div>

                  <div className=" flex items-center mt-3 xs:w-[90%] xs:mx-auto">
                    <input
                      id="link-checkbox"
                      type="checkbox"
                      value=""
                      className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                    />
                    <label
                      for="link-checkbox"
                      className=" ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
                    >
                      I agree to follow buyer posting guideline .
                    </label>
                  </div>

                  {/* buttin action */}

                  <div className=" button-action flex flex-col items-center mt-6">
                    <button
                      type="submit"
                      className="  bg-[#CB2229] flex flex-row-reverse justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                      disabled={loading}
                    >
                      {loading && <Spinner />} Submit
                    </button>
                    <Link to="/findproduct/createnewcampaign">
                      <button className=" mb-6 border-[1px] border-[#CB2229] xs:mt-2 text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                        Create a Campaign
                      </button>
                      </Link>
                  </div>
                </div>
                {/*  */}
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="conditional action b">
            <PromotionFormBody data={getserviceId} />
          </div>
        </>
      )}

      {showModal ? (
        <>
          <PromotionPopup
            setShowModal={setShowModal}
            handleButtonClick={handleButtonClick}
            setnotificationmodal={setnotificationmodal}
          />
        </>
      ) : null}

      {notificationmodal ? (
        <>
          <NotificationModel setnotificationmodal={setnotificationmodal} />
        </>
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
