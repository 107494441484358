import React, { useEffect, useState } from "react";
// importing firebase db.storage from the fireEvent.config
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
// firebase imports  ||
import {
  registerUserdata,
  getAllProductCategories,
} from "../../../src/api/userRegestration/CollectionForm";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
//
import { useNavigate, Link } from "react-router-dom";
import Spinner from "../../component/Spinner";
import { useWallet } from "../Wallet/Context/WalletContext";

function RegisterUserForm() {
  const userID = useWallet();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
    { value: "option4", label: "Option 4" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [DropdownVisible, setDropdownVisible] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    aboutYourself: "",
    location: "",
    interest: "",
    identification: "",
    imageFile: null,
  });
  const [idetificationdropdown, setidetificationdropdown] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedidentification, setSelectedidentication] = useState("");
  const [indetificationopen, setindetificationopen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [imageBase64, setImageBase64] = useState(null);
  const [identiSearchValue, setidentiSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [err, seterr] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
      setidetificationdropdown((prev) => !prev); 
    
  };
  // this part is for the interest field && dropdown
  const toggle_dropdown = () => {
    setindetificationopen(!indetificationopen);
  };
  //
  const handle_ideti_changes = (event) => {
    setidentiSearchValue(event.target.value);
  };
  //
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleInputChanges = (event) => {
    setSearchInput(event.target.value);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file);
      setIsImageSelected(false);
      // Update state when image is selected
    } else {
      setSelectedImage(null);
      setImageBase64(null);
      // Update state when image is not selected
    }
  };
  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      setIsImageSelected(true);
      return; // Prevent form submission if image is not selected
    }
    if (selectedImage) {
      setLoader(true);
    }
    try {
      const userData = {
        name: formData.fullname,
        userName: "",
        email: "",
        gender: "",
        identification: selectedidentification,
         interest: selectedOptions.map(option => option.label),
        bio: formData.aboutYourself,
        dob: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedIn: "",
        location: formData.location,
        profilePic: imageBase64,
        coverPic:
          "https://img.freepik.com/free-vector/abstract-orange-background-with-lines-halftone-effect_1017-32107.jpg?w=1060&t=st=1710240453~exp=1710241053~hmac=817e2ade07c6117080be65152190a6673ce86fcc65dbb19765bd49976bcca175",
      };
      const userId = userID.userID;
      console.log("logging users with id in documet");
      await setDoc(doc(db, "users", userId), {
        uid: userId,
        displayName: formData.fullname,
        photoURL: imageBase64,
      });
      console.log("logging chat is with id in documet");

      await setDoc(doc(db, "userChats", userId), {});
      const registeredUser = await registerUserdata(userData);
      console.log("Registered User:", registeredUser, "result");
      navigate("/");
      window.location.reload();
    } catch (error) {
      seterr(error);
      console.error("Error registering user:", error.message);
    }
  };
  useEffect(() => {
    getAllProductCategories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      // Check if the number of selected options is less than 2
      if (selectedOptions.length < 4) {
        setSelectedOptions([...selectedOptions, option]);
      }
      // Close the dropdown after selecting two options
      setDropdownVisible(false);
    }
  };

  const toggleDropdowns = () => {
    setDropdownVisible(!DropdownVisible);
  };

  return (
    <>
      <>
        {/* 01  */}
        <div className="flex flex-row  xs:justify-center items-center  bg-[#cb22281c] xs:bg-white">
          <div className="xs:hidden item-1 h-screen flex flex-col justify-evenly lg:w-[50%] ">
            <div className="flex flex-col justify-center items-center mt-5 gap-[8pxs]">
              <img src={Logo} alt="" className="lg:w-[104px] fade-in " />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[30px] text-[#738482] mt-2">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            {/*  */}
            <div className="image relative top-[4px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>
            {/*  */}
            <div className="flex flex-row items-center gap-4 justify-center">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          {/* 01 end  */}
          {/* 02  */}
          <div className="item-form ">
            <div className="flex flex-col mx-3">
              <div className="flex flex-col items-center lg:hidden  --logo  justify-center xs:mt-[1rem]">
                <img
                  src={Logo}
                  alt=""
                  className="lg:w-[134px] xs:h-[49px] bounce-in-top mx-auto "
                />
                <div className="text">
                  <h1 className="text-center font-bold text-[17px] leading-[70px] text-black mt-1">
                    Welcome to INO
                  </h1>
                  <p className="text-center  text-black font-all font-semibold ">
                    Register now to gain access to your account{" "}
                  </p>
                </div>
              </div>
            </div>
            <section className=" p-6 bg-white xs:bg-[#cb22281c] rounded-md shadow-md  mt-[2rem]">
              <form
                onSubmit={handleSubmit}
                className="relative rounded-lg mx-[23px] my-[18px] w-auto"
              >
                <div className="flex  flex-row-reverse xs:flex-col-reverse justify-start ">
                  <div className="item&fom w-[25rem] rounded-full xs:w-[17rem]">
                    <div className="grid grid-cols-1  mt-4 xs:mt-1 ">
                      <div className="coolinput flex flex-col w-fit-content static ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                        >
                          Full name
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          className="input px-2 py-3 text-sm border-[1px]  border-gray-300 rounded-md focus:outline-none bg-white    p-[10px]"
                          name="fullname"
                          value={formData.fullname}
                          onChange={handleInputChange}
                          required
                          autoComplete="off"
                        />
                      </div>

                      <div className="coolinput flex flex-col w-fit-content static ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                        >
                          About yourself
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          className="input px-2 py-3 text-sm border-[1px]  border-gray-300 rounded-md focus:outline-none bg-white    p-[10px]"
                          name="aboutYourself"
                          value={formData.aboutYourself}
                          onChange={handleInputChange}
                          required
                          autoComplete="off"
                        />
                      </div>

                      <div className="coolinput flex flex-col w-fit-content static ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                        >
                          Location
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          className="input px-2 py-3 text-sm border-[1px]  border-gray-300 rounded-md focus:outline-none bg-white    p-[10px]"
                          name="location"
                          value={formData.location}
                          onChange={handleInputChange}
                          required
                          autoComplete="off"
                        />
                      </div>

                      <div
                        className="coolinput flex flex-col w-fit-content static"
                        onClick={toggleDropdown}
                      >
                        <label
                          htmlFor="select"
                          className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
                        >
                          Interest
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="category"
                          className="input px-2 py-3 text-sm border-[1px] border-gray-300 rounded-md focus:outline-none bg-white p-[10px]"
                          value={selectedOptions
                            .map((option) => option.label)
                            .join(", ")}
                          required
                          onClick={() => {
                            setDropdownVisible(!DropdownVisible);
                          }}
                          autoComplete="off"
                        />
                        {DropdownVisible && (
                          <ul className="relative z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                            {options.map((option) => (
                              <li
                                key={option.value}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => toggleOption(option)}
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2 cursor-pointer"
                                  checked={selectedOptions.includes(option)}
                                  readOnly
                                />
                                {option.label}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>

                      {/* interest dropdown ended  */}

                      <div className="coolinput flex flex-col w-fit-content static ">
                        <label
                          htmlFor="select"
                          className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
                        >
                          Identification
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="category"
                          className="input px-2 py-3 text-sm border-[1px] border-gray-300 rounded-md focus:outline-none bg-white    p-[10px]"
                          value={selectedCategory} // Use the selectedCategory state here
                          // onChange={handleInputChange}
                          onChange={(e) => {
                            handleInputChange(e);
                            setSelectedCategory(e.target.value); 
                          }}
                          onFocus={() => {
                            setidetificationdropdown(!idetificationdropdown);
                          }}
                          required
                          autoComplete="off"
                        />
                        {idetificationdropdown &&
                          categories.some((category) =>
                            category.name
                              .toLowerCase()
                              .includes(selectedCategory.toLowerCase())
                          ) && (
                            <ul
                              className="absolute h-[18rem] overflow-scroll z-10 xs:w-96 w-[19rem] mt-[4.3rem] bg-white border rounded-md shadow-md slide-in-blurred-top list-disc"
                              style={{ scrollBehavior: "smooth" }}
                            >
                              {categories
                                .filter((category) =>
                                  category.name
                                    .toLowerCase()
                                    .includes(selectedCategory.toLowerCase())
                                )
                                .map((category) => (
                                  <li
                                    key={category._id}
                                    onClick={() => {
                                      setIsOpen(!isOpen);
                                      // toggleDropdown();
                                      setidetificationdropdown(false); 
                                      setSelectedCategory(category.name); 
                                    }}
                                    className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 `}
                                  >
                                    {category.name}
                                  </li>
                                ))}
                            </ul>
                          )}
                      </div>

                      {/* base 64  */}
                      {/* <div className="flex flex-col">
                    <label className="text-black" htmlFor="username">
                      profile pic
                    </label>

                    <div className="flex items-center justify-center w-full">
                      <label
                     
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full  h-[8rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 shark:text-gray-400">
                            SVG, PNG, JPG, or GIF (MAX. 800x400px)
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                      </label>
                      {selectedImage && (
                        <div className="flex items-center  w-auto p-[10px]  h-[8rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                          <img
                            className="w-full h-[7rem] rounded-md mx-auto "
                            src={selectedImage}
                            alt="Selected"
                          />
                        </div>
                      )}
                    </div>
                  </div> */}

                      {/* useer profile image  */}
                      {/* <div className="flex flex-col">
                    <label className="text-black" htmlFor="username">
                      Profile Pic
                    </label>

                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="relative flex flex-col items-center justify-center w-[7rem] h-[7rem] border-2 border-gray-300  rounded-full cursor-pointer bg-gray-50 shark:hover:bg-gray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                      >
                        <img
                          className="w-full h-full rounded-full absolute"
                          src={
                            selectedImage ||
                            "https://as2.ftcdn.net/v2/jpg/05/69/90/73/1000_F_569907313_fl7W3gX7YIVw2r05B4Ij1c21ix4xRUqD.jpg"
                          }
                          alt="Profile Pic"
                        />
                        <div className="w-full h-full group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                          <img
                            className="hidden group-hover:block w-12"
                            src="https://www.svgrepo.com/show/33565/upload.svg"
                            alt="Upload Icon"
                          />
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                      </label>
                    </div>
                  </div> */}
                    </div>
                  </div>
                  <div className="item&image p-2">
                    <div className="flex flex-col">
                      <div className="flex items-center justify-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className="relative flex flex-col items-center justify-center w-[9rem] h-[9rem]   rounded-full cursor-pointer bg-gray-50 shark:hover:bg-gray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                        >
                          <img
                            className="w-full h-full rounded-full absolute"
                            src={
                              selectedImage ||
                              "https://as2.ftcdn.net/v2/jpg/05/69/90/73/1000_F_569907313_fl7W3gX7YIVw2r05B4Ij1c21ix4xRUqD.jpg"
                            }
                            alt="Profile Pic"
                          />
                          <div className="w-full h-full group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                            {/* <img
                              className="hidden group-hover:block w-12"
                              src="https://www.svgrepo.com/show/33565/upload.svg"
                              alt="Upload Icon"
                            /> */}
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            onChange={handleImageChange}
                            // required
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {!isImageSelected && (
  <p className="text-red-500">Please select an image</p>
)} */}
                {isImageSelected && (
                  <p className="text-red-500">select image to continue</p>
                )}
                {/* {err? <>  <p className="text-red-500">{err}</p></>:""} */}
                {err && <p className="text-red-500">{err.message}</p>}

                <div className="flex justify-center  mt-2 xs:mt-8 ">
                  <button
                    type="submit"
                    onClick={() => {
                      // if (!selectedImage) {
                      //   setIsImageSelected(true);
                      //   return; // Prevent form submission if image is not selected
                      // }
                    }}
                    className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#cb2229] rounded-full focus:outline-none focus:bg-gray-600"
                  >
                    {loader ? (
                      <>
                        <span className="flex flex-row items-center gap-2">
                          <Spinner />
                        </span>
                      </>
                    ) : (
                      <> Proceed</>
                    )}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
        {/* 02 end  */}
      </>
    </>
  );
}

export default RegisterUserForm;
