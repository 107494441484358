// import React, { useContext, useEffect, useRef } from "react";
// import { AuthContext } from "../../../Context/AuthContext";
// import { ChatContext } from "../../../Context/ChatContext";

// const Message = ({ message }) => {
//   const { currentUser } = useContext(AuthContext);
//   const { data } = useContext(ChatContext);

//   const ref = useRef();

//   useEffect(() => {
//     ref.current?.scrollIntoView({ behavior: "smooth" });
//   }, [message]);

//   return (
//     <div
//     ref={ref}
//     className={`message ${message.senderId === currentUser.uid && "owner"}`}
//   >
//     <div className="messageInfo">
//       <img
//         src={
//           message.senderId === currentUser.uid
//             ? currentUser.photoURL
//             : data.user.photoURL
//         }
//         alt=""
//       />
//     </div>
//     <div className="messageContent">
//     <div
//           className={`chat-bubble ${
//             message.senderId === currentUser.uid ? "bg-[#E98F92] text-black" : "bg-[#FAC8CA] text-black"
//           } font-all`}
//         >
//         {message.text}
//       </div>
//       {message.img && <img src={message.img} alt="" />}
//     </div>
//   </div>
//   );
// };

// export default Message;

import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import { ChatContext } from "../../../Context/ChatContext";

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
  //   <div
  //   ref={ref}
  //   className={`message flex ${message.senderId === currentUser.uid && "owner"}`}
  // >
  //   <div className="messageInfo">
  //     <img
  //     className="w-10 h-[10]"
  //       src={
  //         message.senderId === currentUser.uid
  //           ? currentUser.photoURL
  //           : data.user.photoURL
  //       }
  //       alt=""
  //     />
  //   </div>
  //   <div className="messageContent">
  //   <div
  //         className={`chat-bubble chat-end ${
  //           message.senderId === currentUser.uid ? "bg-[#E98F92] text-black " : "bg-[#FAC8CA] text-black"
  //         } font-all`}
  //       >
  //       {message.text}
  //     </div>
  //     {message.img && <img src={message.img} alt="" />}

      
  //   </div>
  // </div>

  <>
<div
  ref={ref}
  className={`message flex ${message.senderId === currentUser.uid ? "owner flex justify-end" : ""}`}
>
  <div className="messageInfo">
    {/* <img
      className="w-10 h-10"
      src={
        message.senderId === currentUser.uid
          ? currentUser.photoURL
          : data.user.photoURL
      }
      alt=""
    /> */}
  </div>
  <div className="messageContent">
    <div
      className={`chat ${message.senderId === currentUser.uid ? " chat chat-end" : "chat chat-start"}`}
    >
      <div
        className={`chat-bubble ${
          message.senderId === currentUser.uid ? "bg-[#E98F92] text-black  chat chat-end" : "bg-[#FAC8CA] text-black"
        } font-all`}
      >
        {message.text}
      </div>
    </div>
    {/* {message.img && <img src={message.img} alt="" />} */}
  </div>
</div>

  
  </>
  );
};

export default Message;