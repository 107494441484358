import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'

export default function PromotionBillingAddress() {
  return (
<>
<div className="--item-c--input-form-velow-section">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[98%] xs:p-2 mx-auto shadow-sm mb-3 mt-4">
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[98%] lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  VPA Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <h1 className=" font-all font-semibold text-[14px] text-start ml-6 mt-3 mb-2">
                Billing address
              </h1>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[98%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[98%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Street Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[98%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  City
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[98%] lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  state / province
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[98%] lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Postal Code
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[98%] lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Tax Status
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              <div className="collinpu-text-area--img  flex flex-col w-fit-content static xs:w-[98%] max-w-240 lg:w-[32rem]">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Billing Address & Text Information
                </label>
                <div className="mb-4  h-[9rem] input px-2 py-3 text-sm border-[1px] border-[#738482] border-dashed rounded-md focus:border-[1px]">
                  <span className="p-[40px] text-[42px] flex justify-center items-center text-[#D2D2D2]">
                    <AiOutlinePlusCircle />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

</>
  )
}
