import React from 'react'
import { IoChatbubblesOutline, IoHomeOutline, IoList, IoWalletOutline } from 'react-icons/io5'
import { VscAccount } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

export default function MobileBottomNavbar() {


  
  return (
    <>
    <>
  {/* component */}
  <div className="">
   
    {/* <section id="bottom-navigation" className=" md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile*/}
    <section
      id="bottom-navigation"
      className="block fixed inset-x-0 bottom-0 z-10 bg-gray-100 shadow w-full "
    >
      <div id="tabs" className="flex justify-between">
        
      <Link
          to="/"
          className="w-full focus:text-teal-500 hover:text-teal-500  text-center pt-2 pb-1 flex flex-col items-center justify-center"
        >
         
          <span className='text-[25px] w-[25px]'>
          <IoHomeOutline />

          </span>
          <span className="tab tab-kategori block text-xs">Home</span>
        </Link>
        
        <Link
          to="/chat"
          className="w-full focus:text-teal-500 hover:text-teal-500  text-center pt-2 pb-1 flex flex-col items-center justify-center"
        >
         
          <span className='text-[25px] w-[25px]'>
          <IoChatbubblesOutline />

          </span>
          <span className="tab tab-kategori block text-xs">Chats</span>
        </Link>
      
        <Link
          to="/wallet"
          className="w-full focus:text-teal-500 hover:text-teal-500  text-center pt-2 pb-1 flex flex-col items-center justify-center"
        >
         
          <span className='text-[25px] w-[25px]'>
          <IoWalletOutline />

          </span>
          <span className="tab tab-kategori block text-xs">Wallet</span>
        </Link>
      
        <Link
          to="/userProfile"
          className="w-full focus:text-teal-500 hover:text-teal-500  text-center pt-2 pb-1 flex flex-col items-center justify-center"
        >
         
          <span className='text-[25px] w-[25px]'>
          <VscAccount />

          </span>
          <span className="tab tab-kategori block text-xs">Profile</span>
        </Link>

 
        <Link
          to="/more"
          className="w-full focus:text-teal-500 hover:text-teal-500  text-center pt-2 pb-1 flex flex-col items-center justify-center"
        >
         
          <span className='text-[25px] w-[25px]'>
          <IoList />

          </span>
          <span className="tab tab-kategori block text-xs">More</span>
        </Link>
      </div>
    </section>
  </div>
</>

    </>
  )
}
