// import React, { useContext } from 'react'
// import {signOut} from "firebase/auth"
// import { auth } from '../../../firebase'
// import { AuthContext } from "../../../Context/AuthContext";


// const Navbar = () => {
//   const {currentUser} = useContext(AuthContext)

//   return (
   
//     // this component is used to show the current register user sender information 
//     <div className='navbar'>
//       <div className="user">
//         <img src={currentUser.photoURL} alt="" />
//         <span className='text-yellow-500'>{currentUser.displayName}</span>
    
//       </div>
//     </div>
//   )
// }

// export default Navbar

import React from 'react'

function Navbar() {
  return (
  <>
  
  <div className="py-2 px-3 bg-grey-lighter flex flex-row justify-end items-center">
                  <button className="py-1 px-1  rounded-full bg-gray-300 rounde">
                    create Group
                  </button>
                </div>
  </>
  )
}

export default Navbar