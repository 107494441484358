import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchSProductDetails } from "../../../../api/userRegestration/CollectionForm";

import ProductBuyPAgeSlider from "../HomePAll/ProductBuyPAgeSlider";
import UserChatButton from "../../../../Widgets/UserChatButton";
import { ChatContext } from "../../../../Context/ChatContext";
import Loader from "../../../../component/Loader";

export default function Productpage() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { receiverId, setReceiverId } = useContext(ChatContext);
  const [loading, setloading] = useState(true);
  const [productdetails, setproductdetails] = useState();
  const Home = () => {
    navigate(`/`);
  };

  // const location = useLocation();

  const handleClick = () => {
    navigate(`/`);
  };

  useEffect(() => {
    fetchSProductDetails(productId)
      .then((response) => {
        console.log("API Response:", response);
        setproductdetails(response.result);
        setloading(false);
        setReceiverId(response.result.creatorId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [productId]);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md  mb-[7rem] xs:w-auto xs:ml-auto  ml-[17%] w-[81%] relative top-[1rem]">
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          productdetails && (
            <>
              <div className="--action breadcrumb--navigate-path ml-8 mt-8">
                <Breadcrumb aria-label="Default breadcrumb example">
                  <Breadcrumb.Item icon={HiHome}>
                    <p className="cursor-pointer " onClick={Home}>
                      Home
                    </p>
                  </Breadcrumb.Item>

                  <Breadcrumb.Item
                    className="cursor-pointer"
                    onClick={handleClick}
                  >
                    Product
                  </Breadcrumb.Item>

                  <Breadcrumb.Item>
                    {productdetails.productName}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <section className="/">
                <div className=" lg:container mx-auto px-4">
                  <div className="lg:col-gap-8 xl:col-gap-16 mt-8 grid grid-cols-1  lg:mt-12 lg:grid-cols-4 lg:gap-6">
                    <div className="lg:col-span-2 lg:row-end-1">
                      <div className="lg:flex lg:items-start">
                        <div className="lg:order-2 lg:ml-5">
                          <div className="max-w-xl overflow-hidden rounded-lg">
                            <img
                              className="xs:h-full h-[20rem]  w-full max-w-full object-cover"
                              src={productdetails.mediaUrl}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                      <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl  xs:text-2xl font-all">
                        {productdetails.productName} <span> </span>
                      </h1>

                     
                      <div className="mt-3 flex select-none flex-wrap items-center  xs:text-xl gap-1">
                      <h2 className=" text-base text-gray-900  xs:text-3xl">
                        Product Categories
                      </h2>
                        <label>
                          <p className=" bg-gray-200 px-5 py-1 font-bold">
                            {productdetails.categorie}
                          </p>
                        </label>
                      </div>
                      {/*  */}
                      {/* Product rate  */}
                      <h2 className="mt-8 text-base text-gray-900 xs:text-xl font-bold font-all">
                        Product rate :{" "}
                        <span className="italic font-normal">
                          {" "}
                          {productdetails.amount}{" "}
                        </span>
                      </h2>
                      {/* minimum order quanity  */}
                      <h2 className="mt-3 text-base text-gray-900 xs:text-xl font-bold font-all">
                        Minimum Order Quanity (MOD) :{" "}
                        <span className="italic font-normal">
                          {" "}
                          {productdetails.moq}{" "}
                        </span>
                      </h2>
                      <h2 className="mt-3 text-base text-gray-900 xs:text-xl font-bold font-all">
                        MQ unit :{" "}
                        <span className="italic font-light">
                          {" "}
                          {productdetails.mqu}{" "}
                        </span>{" "}
                      </h2>
                      <h2 className="mt-3 text-base text-gray-900 xs:text-xl font-bold font-all">
                        Product Details :
                        <span className="italic font-normal">
                          {" "}
                          " {productdetails.details} "
                        </span>
                      </h2>

                      <div className="flex flex-col gap-[1rem] items-center ">
                        <UserChatButton receiverId={receiverId} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
        )}
      </div>
    </>
  );
}
