import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { useNavigate } from "react-router-dom";
import {
  verifyOTP,
  Receive_otp,
} from "../../api/userRegestration/CollectionForm";
import { useParams } from "react-router-dom";
import Spinner from "../../component/Spinner";
import { useWallet } from "../Wallet/Context/WalletContext";

export default function VerifyPhoneOtp() {
  const userID = useWallet();
  const { mobileNumber } = useParams();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [resendText, setResendText] = useState("Get otp again");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [showinputerror, setshowinputerror] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token");
  console.log("selected user", userID.userID);
  const handleOtpChange = (index, value) => {
    if (/^[0-9]*$/.test(value)) {
      setshowinputerror(false);
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Move to the next input field if the current one is filled
      if (value !== "" && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
        setCurrentIndex(index + 1);
      }
    }
  };

  const handleBackspace = (index, value) => {
    if (value === "" && index > 0) {
      inputRefs[index - 1].current.focus();
      setCurrentIndex(index - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    console.log("data", mobileNumber, enteredOtp);
    // startResendTimer();

    if (otp.some((digit) => digit === "")) {
      console.log("validate state updated");
      setshowinputerror(true);
      return; // Exit the function early
    }

    try {
      setspinner(true);
      const result = await verifyOTP(mobileNumber, enteredOtp);

      console.log(result);

      setspinner(false);
      if (isAuthenticated) {
        navigate("/");
        window.location.reload();
      } else {
        navigate("/auth/registerUser");
      }
    } catch (error) {
      // Handle error
    }
    setspinner(false);
  };

  const startResendTimer = () => {
    setResendDisabled(true);
    let seconds = 45;
    const intervalId = setInterval(() => {
      seconds--;
      if (seconds === 0) {
        clearInterval(intervalId);
        setResendText("get OTP ");
        setResendDisabled(false);
      } else {
        setResendText(
          `recieving otp in 0:${seconds < 10 ? `0${seconds}` : seconds}`
        );
      }
    }, 1000);
  };

  const handle_Receive_newOtp = async () => {
    startResendTimer();
    console.log(mobileNumber, "nested i to counter ");
    try {
      const result = await Receive_otp(mobileNumber);
      console.log("new otp counter strike ", result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  return (
    <>
      <div className="xs:min-h-screen bg-[#cb22290f] xs:bg-transparent">
        <div className="lg:hidden  --logo flex justify-center xs:mt-[2rem]">
          <img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top"
          />
        </div>
        <div className="flex flex-row justify-start">
        <div className="xs:hidden item-1 h-screen flex flex-col justify-evenly lg:w-[50%] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-5 gap-[8pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[104px] fade-in "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[30px] text-[#738482] mt-2">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            {/*  */}
            <div className="image relative top-[4px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>
            {/*  */}
            <div className="flex flex-row items-center gap-4 justify-center">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className=" fade-in-left item-2  bg-[#cb22290f]  xs:bg-white w-[60%] flex justify-center items-center xs:w-[100%]">
            <div className="bg-white xs:bg-[#cb22290f] shadow-md w-[88%] rounded-md mt-[3rem] xs:mt-8 md:mt-4 h-max p-8 drop-c fade-in-right xs:mx-auto">
              <h1 className="text-all text-center font-bold xs:font-all xs:font-semibold text-[22px] relative xs:top-[-1px]">
                Verify OTP
              </h1>
              <h3 className="text-center text-[#000000] lg:mt-[30px]">
                Waiting to automatically detect an SMS sent to{" "}
                <span className="font-bold "> "{mobileNumber}" </span>
              </h3>
              <p className="text-[#8C8C8C] text-center my-4">enter otp</p>
              <form onSubmit={handleSubmit}>
                <div className="input-flex-row flex flex-row gap-[22px] xs:gap-[6px] justify-center mt-[13px] ">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      className="w-[40px] focus:border- mont-serif appearance-none py-2 px-3 bg-white dark:bg-white text-gray-700 leading-tight focus:outline-none border border-b rounded-md border-gray-400"
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) =>
                        e.key === "Backspace" && handleBackspace(index, digit)
                      }
                    />
                  ))}
                </div>
                {/* <hr className="border border-[#738482] mt-[4.25rem] w-[50%] mx-auto m-auto" /> */}
                <div className={showinputerror ? "block" : "hidden"}>
                  <span className="text-sm text-red-600 fade-in flex justify-center mt-4">
                    Verify OTP to continue !
                  </span>
                </div>
                <p className="text-[#8C8C8C] text-center mt-[18px]">
                  Enter 4-Digit Code
                </p>
                <p className="text-center text-[12px] mt-[50px] underline underline-offset-2 cursor-pointer">
                  Didn’t receive the code?
                </p>
                <div className="flex flex-col gap-2 mt-[20px]">
                  <button
                    onClick={handle_Receive_newOtp}
                    className={`flex justify-center items-center mx-auto m-auto border-[1px] ${
                      resendDisabled
                        ? "border-gray-400 text-gray-400 cursor-not-allowed"
                        : "border-[#CB2229] text-black"
                    } rounded-full font-all p-[2px] w-[15rem] transform hover:scale-105 duration-500 ease-in-out`}
                    disabled={resendDisabled}
                  >
                    {resendText}
                  </button>

                  {/* onClick={handleNextClick} */}
                  {/* <Link to="/auth/registerUser"> */}
                  <button
                    type="submit"
                    // onClick={data}
                    className="flex justify-center items-center mx-auto  border-[1px] bg-[#CB2229] text-white dark:text-white rounded-full font-all p-[2px] w-[15rem] transform hover:scale-105 duration-500 ease-in-out"
                  >
                    {spinner ? (
                      <>
                        <span className="flex flex-row gap-2 text-white dark:text-white">
                          verifying <Spinner />
                        </span>
                      </>
                    ) : (
                      <>verify otp</>
                    )}
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
