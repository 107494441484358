import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useWallet } from "../pages/Wallet/Context/WalletContext";

function MobileMorePage() {
    const navigate= useNavigate();
const {isCustomDropdownOpen,setIsCustomDropdownOpen} = useWallet()
  return (
    <>
      <div className=" h-full ">
        <div className=" px-4">
          <div className="collapse collapse-arrow border-t-2">
            <input type="radio" name="my-accordion-2"  />
            <div className="collapse-title text-xl font-medium">Accounts</div>
            <div className="collapse-content">
              <button onClick={()=>{navigate("/update_phone_number")}} >Change Number</button>
              {/* <br />
              <button>Delete Account</button> */}
            </div>
          </div>

          <div className=" border-t-2">
            <button onClick={()=>{navigate("/contact-us")}}  className=" ml-4 text-xl font-medium py-4">Contact Us</button>
          </div>
          <div className=" border-t-2">
            <button onClick={()=>{navigate("/TermsandService")}} className=" ml-4 text-xl font-medium py-4">Terms Of Services</button>
          </div>
          <div className=" border-t-2">
            <button onClick={()=>{navigate("/Privacy&Policy")}} className=" ml-4 text-xl font-medium py-4">Privacy Policy</button>
          </div>
          <div className=" border-t-2">
            <button onClick={()=>{navigate("/referal")}} className=" ml-4 text-xl font-medium py-4">Referal Link</button>
          </div>
          <div className=" border-t-2">
            <button onClick={()=>{navigate("/License")}} className=" ml-4 text-xl font-medium py-4">Licences</button>
          </div>
          <div className=" border-t-2">
            <button onClick={()=>{navigate("/Helpcenter")}} className=" ml-4 text-xl font-medium py-4">Help Center</button>
          </div>
          <div className=" border-y-2 mb-6" onClick={()=>{
                console.log("clicked")
                localStorage.removeItem("token")
                navigate("/auth/welcome")
              
            }}>
            <button  className=" ml-4 text-xl  font-medium py-4">Logout</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileMorePage;
